import React, { Fragment, useEffect, useState } from "react";
import {getNews, getDocumentFile} from "../fetchers";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, NewspaperIcon } from "@heroicons/react/24/outline";
import { classNames } from "shared/src/utils/classNames";
import {useSelector} from "react-redux";
import {PollworkerPublicState} from "../state/reducers";
import { downloadDocument, getFileExtension } from "shared/src/utils/downloadDocument";
import { toast } from "react-toastify";
import PageEmptyState from "./PageEmptyState";
import { Spinner } from "shared/src/components";
import {ScreenReaderOnly} from "shared/src/components/Accessibility";

type NewsFile = {
  Id: string,
  Name: string,
  URL: string,
}

type News = {
  Id: string,
  Subject: string,
  DisplayDate: string,
  Message: string,
  Files: NewsFile[],
}

export default function NewsBlog() {
  const currentUser = useSelector((state: PollworkerPublicState) => state.currentPollworkerUser);
  const [newsBlogs, setNewsBlogs] = useState<News[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    loadNews();
  }, [currentUser.Customer.Id]);

  function loadNews() {
    setLoading(true);

    return getNews(currentUser.Customer.Id)
    .then(setNewsBlogs)
    .finally(() => setLoading(false));
  }

  function downloadNewsDocumentFile(file: NewsFile) {
    const {Id: fileId, Name: fileName, URL: url} = file;

    getDocumentFile(fileId)
    .then(async (resp) => {
      if (!resp.ok) return toast.error('Not able to download file. Try again.')
      downloadDocument(fileName, getFileExtension(url))(await resp.blob())
    });
  }

  return (
    <div className="mt-2">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1
            className="text-base font-semibold leading-6 text-gray-900"
            role="heading"
          >News</h1>
          <p className="mt-2 text-sm text-gray-700">
            News items and other announcements are displayed here. All important information about classes or schedules
            will be displayed here. Click the row with the News item you wish to view and the details will be displayed
            at the bottom of the screen.
          </p>
        </div>
      </div>

      {
        loading ? (
          <div className='w-full flex justify-center items-center h-24 my-24 space-x-2'>
            <Spinner show />
            <span className='text-lg'>Loading news...</span>
          </div>
        ) : (
          <>
            {
              newsBlogs.length ? (
                <div className="-mx-4 mt-8 sm:-mx-0">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                    <tr>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                        Subject
                      </th>
                      <th
                        scope="col"
                        className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                      >
                        Date
                      </th>
                      <th
                        scope="col"
                        className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                      >
                        Message
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Files
                      </th>
                    </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                    {newsBlogs.map((news) => (
                      <tr key={news.Id}>
                        <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0">
                          <span className="hidden md:inline">{news.Subject}</span>
                          <dl className="font-normal md:hidden">
                            <dt className="sr-only">Subject</dt>
                            <dd className="mt-1 truncate text-gray-700 font-medium md:hidden">{news.Subject}</dd>
                            <dt className="sr-only sm:hidden">, Message</dt>
                            <dd className="mt-1 truncate text-gray-500 sm:hidden">{news.Message}</dd>
                          </dl>
                        </td>
                        <td className="hidden px-3 py-4 text-sm text-gray-800 lg:table-cell">{news.DisplayDate}</td>
                        <td className="hidden px-3 py-4 text-sm text-gray-800 sm:table-cell">{news.Message}</td>
                        <td className="px-3 py-4 text-sm text-gray-800">
                          {
                            news.Files?.length ? (
                              <Menu as="div" className="relative inline-block text-left">
                                <div>
                                  <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 text-sm font-semibold text-gray-900">
                                    View {news.Files.length} {news.Files.length > 1 ? 'files' : 'file'}
                                    <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                                  </Menu.Button>
                                </div>

                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="py-1">
                                      {
                                        news.Files.map((file) => {
                                          return (
                                            <Menu.Item key={file.Id}>
                                              {({ active }) => (
                                                <button
                                                  onClick={() => downloadNewsDocumentFile(file)}
                                                  className={classNames(
                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                    'block text-left px-4 py-2 text-sm w-full'
                                                  )}
                                                >
                                                  {file.Name}
                                                  <ScreenReaderOnly>, starts download</ScreenReaderOnly>
                                                </button>
                                              )}
                                            </Menu.Item>
                                          )
                                        })
                                      }
                                    </div>
                                  </Menu.Items>
                                </Transition>
                              </Menu>
                            ) : (<div className='text-gray-500 italic'>No files available</div>)
                          }
                        </td>
                      </tr>
                    ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className='my-10'>
                  <PageEmptyState icon={NewspaperIcon} emptyMessageHeader='No news to report' emptyMessageSubHeader='No news yet, check back soon to stay up-to-date!' />
                </div>
              )
            }
          </>
        )
      }
    </div>
  );
}
