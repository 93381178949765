import React, {useCallback, useEffect, useState} from 'react';

import { WorkSchedule } from 'pollworker/src/types';

import PollworkerScheduleCard from './PollworkerScheduleCard';
import { cn } from 'shared/src/utils';
import { Input } from 'shared/src/components/ui';

export default PollworkerScheduleCardGrid;

export interface PollworkerScheduleCardGridProps {
  className: string | undefined;
  pollworkerSchedules: WorkSchedule[];
  onClockIn: (schedule: WorkSchedule) => Promise<void>;
  onClockOut: (schedule: WorkSchedule) => Promise<void>;
}
function PollworkerScheduleCardGrid({ className, pollworkerSchedules, onClockIn, onClockOut } : PollworkerScheduleCardGridProps) {
  const [nameSearch, setNameSearch] = useState<string>('');
  const [filteredSchedules, setFilteredSchedules] = useState<WorkSchedule[]>();

  const handleSearchChange = useCallback((newSearch: string) => {
    setNameSearch(newSearch);
  }, [setNameSearch]);

  useEffect(() => {
    if (!!nameSearch) {
      setFilteredSchedules(
        pollworkerSchedules.filter(
          schedule => schedule.PollworkerFullName.toLowerCase().indexOf(nameSearch.toLowerCase()) >= 0
        )
      );
    } else {
      setFilteredSchedules(pollworkerSchedules);
    }
  }, [nameSearch, pollworkerSchedules]);

  return (
    <div className="h-full w-full">
      <div className="p-4 pb-0">
        <Input placeholder="Search for worker..."
               value={nameSearch}
               onChange={(event) => handleSearchChange(event.target.value)}
        />
      </div>
      <div className={cn(className, "flex flex-wrap gap-2")}>
        {filteredSchedules && filteredSchedules.map((schedule) => (
          <PollworkerScheduleCard
            key={schedule.Id}
            pollworkerSchedule={schedule}
            onClockIn={onClockIn}
            onClockOut={onClockOut}
          />
        ))}
      </div>
    </div>
  );
};
