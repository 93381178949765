import dayjs, { Dayjs } from "dayjs";
import { classNames } from "shared/src/utils/classNames";
import React, { useEffect, useMemo, useState } from "react";

function generateCalendarDates(startDay: Dayjs, selectedDay?: Dayjs) {
  const today = startDay;
  const startOfCurrentMonth = today.startOf('month');
  const endOfCurrentMonth = today.endOf('month');
  const dates = [];

  let start = startOfCurrentMonth.subtract(startOfCurrentMonth.day(), 'day');
  let end = endOfCurrentMonth.add(7 - endOfCurrentMonth.day(), 'day');

  while (start.isBefore(end, 'day')) {
    const date = start.format('YYYY-MM-DD');
    const dateObject = { date, isSelected: start.isSame(selectedDay), isCurrentMonth: false, isToday: false };

    if (start.month() === today.month()) {
      dateObject.isCurrentMonth = true;
    }

    if (start.isSame(today, 'day')) {
      dateObject.isToday = true;
    }

    dates.push(dateObject);
    start = start.add(1, 'day');
  }

  return dates;
}

export default function Calendar({startDay = dayjs(), onChange}: {startDay: Dayjs, onChange: (date: Dayjs) => void}) {
  const [selectedDay, setSelectedDay] = useState<Dayjs>();

  const days = useMemo(() => generateCalendarDates(startDay, selectedDay), [startDay, selectedDay]);

  useEffect(() => {
    setSelectedDay(startDay)
  }, [startDay]);

  function changeSelectedDate(date: Dayjs) {
    setSelectedDay(date);
    onChange(date);
  }

  return (
    <div className="text-center">
      <div className="flex items-center text-gray-900">
        <div className="flex-auto text-sm font-semibold">{selectedDay?.format('MMMM')}</div>
      </div>
      <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
        <div>S</div>
        <div>M</div>
        <div>T</div>
        <div>W</div>
        <div>T</div>
        <div>F</div>
        <div>S</div>
      </div>
      <div data-testid='calendar' className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
        {
          days.map((day, dayIdx) => (
            <button
              key={day.date}
              data-testid={day.date}
              type="button"
              onClick={() => changeSelectedDate(dayjs(day.date))}
              className={classNames(
                'py-4 focus:z-10',
                day.isToday ? 'hover:bg-ev-red/50' : 'hover:bg-gray-100',
                day.isToday && day.isCurrentMonth ? 'bg-ev-red' : 'bg-white',
                (day.isSelected || day.isToday) && 'font-semibold',
                day.isSelected && 'text-white',
                !day.isSelected && day.isCurrentMonth && !day.isToday && 'text-gray-900',
                !day.isSelected && !day.isCurrentMonth && !day.isToday && 'text-gray-800',
                day.isToday && !day.isSelected && 'text-white',
                (dayIdx % 7 === 0 || dayIdx % 7 === 6) ? 'text-gray-200' : 'text-gray-800',
                dayIdx === 0 && 'rounded-tl-lg',
                dayIdx === 6 && 'rounded-tr-lg',
                dayIdx === days.length - 7 && 'rounded-bl-lg',
                dayIdx === days.length - 1 && 'rounded-br-lg'
              )}
            >
              <time
                dateTime={day.date}
                className={classNames(
                  'mx-auto flex h-7 w-7 items-center justify-center rounded-full',
                  // day.isToday && 'bg-ev-red',
                  day.isSelected && !day.isToday && 'bg-gray-900',
                )}
              >
                {/* @ts-ignore */}
                {day.date && day.date.split('-').pop().replace(/^0/, '')}
              </time>
            </button>
          ))
        }
      </div>
      <p className='italic mt-2 text-sm text-gray-500'>Choose a date above to filter the list</p>
    </div>
  )
}
