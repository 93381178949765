import {useAppDispatch} from "./index";
import {useLocation} from "wouter";
import {logoutPollworkerUser} from "../state/actions";

export function useLogOut() {
  const dispatch = useAppDispatch();
  const [, navigate] = useLocation();

  return function logout() {
    window.localStorage.removeItem('CurrentUser');
    window.localStorage.removeItem('ZumoToken');
    dispatch(logoutPollworkerUser());
    navigate('/');
  }
}