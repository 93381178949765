import {
  ArrowRightIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon
} from "@heroicons/react/24/outline";
import React, { FormEvent, useState } from "react";
import Spinner from "./Spinner";
import EasyVoteLogo from "./EasyVoteLogo";

export interface ForgotPasswordProps {
  startPasswordReset: (username: string) => Promise<boolean>;
  onSuccessfulReset: (username: string) => void;
}

export default function ForgotPassword({ startPasswordReset, onSuccessfulReset} : ForgotPasswordProps) {
  const [username, setUsername] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);

  const errorMessage = null;

  function handleSubmit(event: FormEvent) {
    event.preventDefault();

    if (!username) return;

    setLoading(true);

    startPasswordReset(username)
    .then((success) => {

      if (success) {
        setShowSuccessMessage(true);
        onSuccessfulReset(username);
      }
    });
  }

  return (
    <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <EasyVoteLogo
          className="mx-auto h-12 w-auto"
        />
        <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">Forgot password</h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg shadow-md border border-gray-200/80 sm:px-10">
          {
            errorMessage ? (
              <div className="rounded-md bg-red-50 p-4 mb-2">
                <div className="flex items-center">
                  <ExclamationTriangleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-red-800">{errorMessage}</h3>
                  </div>
                </div>
              </div>
            ) : null
          }
          {
            !errorMessage || showSuccessMessage ? (
              <div className="rounded-md bg-blue-50 p-4 mb-8">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3 flex-1">
                    <p className="text-sm text-blue-700"> Once your ID is validated, a security code will be sent to your email address or SMS-capable device. This code, and the new password may be entered on the next page.</p>
                  </div>
                </div>
              </div>
            ) : null
          }
          <form onSubmit={handleSubmit} className="space-y-6" action="admin/src/components/PollworkerPublic#" method="POST">
            <div>
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                EasyVote User ID
              </label>
              <div className="mt-2">
                <input
                  id="username"
                  name="username"
                  type="text"
                  autoFocus={true}
                  placeholder='Your EasyVote User ID'
                  required
                  onChange={({target: {value}}) => setUsername(value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ev-red sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                disabled={loading}
                className="space-x-2 flex w-full flex items-center justify-center rounded-md bg-ev-red py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-ev-red focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ev-red disabled:bg-ev-red/50"
              >
                <Spinner show={loading} light />
                <span>Next</span>
                <ArrowRightIcon className='h-3 w-3' />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

