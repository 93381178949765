import { ArrowPathIcon, ExclamationTriangleIcon, } from "@heroicons/react/24/outline";
import React, { ElementType, ReactNode } from "react";

type PageEmptyStateProps = {
  icon: ElementType,
  errorMessage?: string,
  emptyMessageHeader: string,
  emptyMessageSubHeader: string,
  reload?: () => void
  children?: ReactNode,
};

export default function PageEmptyState({icon: Icon, errorMessage, emptyMessageHeader, emptyMessageSubHeader, reload, children}: PageEmptyStateProps) {
  return (
    <div className=' space-y-5'>
      {
        errorMessage ? (
          <div className="rounded-md bg-red-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <ExclamationTriangleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
              </div>
              <div className="ml-3 flex-1 md:flex md:justify-between">
                <p className="text-sm text-red-700">{errorMessage}</p>
                {
                  reload ? (
                    <p className="mt-3 text-sm md:ml-6 md:mt-0">
                      <button onClick={() => reload()} className="flex space-x-2 items-center whitespace-nowrap font-medium text-red-700 hover:text-red-600">
                        <span>Retry</span>
                        <ArrowPathIcon className='h-4 w-4' />
                      </button>
                    </p>
                  ) : null
                }
              </div>
            </div>
          </div>
        ) : null
      }
      <div className="text-center border border-dashed rounded-md border-gray-300 p-10">
        <Icon className='mx-auto h-12 w-12 text-gray-400' />
        <h3 className="mt-2 text-sm font-semibold text-gray-900">{emptyMessageHeader}</h3>
        <p className="mt-1 text-sm text-gray-500">{emptyMessageSubHeader}</p>
        { children }
      </div>
    </div>
  )
}
