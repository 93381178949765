import PageEmptyState from "./PageEmptyState";
import {ArrowDownTrayIcon, DocumentTextIcon, NoSymbolIcon} from "@heroicons/react/24/outline";
import {classNames} from "shared/src/utils/classNames";
import React from "react";
import {ClassSession} from "./ScheduledClasses";
import {Document} from "./ScheduledClasses";
import {getDocumentFile} from "../fetchers";
import {downloadDocument} from "shared/src/utils/downloadDocument";
import {toast} from "react-toastify";
import {ScreenReaderOnly} from "shared/src/components/Accessibility";

export function DocumentsPanel({ classDetails }: { classDetails?: ClassSession }) {
  const documents = classDetails?.Documents || [];

  if (!documents.length) {
    return (
      <div className='mt-4'>
        <PageEmptyState icon={NoSymbolIcon} emptyMessageHeader='No documents found' emptyMessageSubHeader='' />
      </div>
    )
  }

  return (
    <ul className="mt-5 mb-2 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-2">
      {documents.map((document: Document) => (
        <li key={document.Id} className="col-span-1 flex rounded-md shadow-sm">
          <div className='flex flex-1 items-center justify-between truncate border-gray-200 border-2 rounded-md'>
            <div
              className={classNames(
                'flex w-10 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-ev-red'
              )}
            >
              <DocumentTextIcon className='h-10 w-10 ml-3' />
            </div>
            <div className="flex flex-1 items-center justify-between truncate">
              <div className="flex-1 truncate px-4 py-2 text-gray-500 text-xs">
                <button onClick={() => downloadClassDocumentFile(document)} className="text-gray-900 hover:text-gray-600 cursor-pointer">
                  {document.Name}
                </button>
                <p><span tabIndex={-1}>{document.UploadDate}</span></p>
                <p><span tabIndex={-1}>{document.UploadBy}</span></p>
              </div>
              <div className="flex-shrink-0 pr-2">
                <button
                  type="button"
                  className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-ev-red focus:ring-offset-2"
                  onClick={() => downloadClassDocumentFile(document)}
                >
                  <ScreenReaderOnly>Download file</ScreenReaderOnly>
                  <ArrowDownTrayIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  )
}

function downloadClassDocumentFile(file: { Id?: any; Name?: any; UploadDate?: string; UploadBy?: string; Url?: any; }) {
  const fileExtension = file.Url.substring(file.Url.lastIndexOf("."));

  const downloadingFile = new Promise((resolve, reject) => {
    getDocumentFile(file.Id)
      .then(async (response) => {

        if (!response.ok) {
          setTimeout(reject, 2000);
          return;
        }

        downloadDocument(file.Name, fileExtension)(await response.blob());
        resolve(true);
      });
  });

  toast.promise(downloadingFile, { pending: 'Downloading file...', success: 'Download complete!', error: 'We could not download the file.' }, {
    closeButton: (<div></div>),
    autoClose: 2000,
  });
}
