/*
 * Our api returns a very fun dates format... /Date(123456789)/
 * This converts that format to an ISO string
 */
export function apiDateConverter(workDate: string) {
  const value = /\/Date\((\d.*)\)\//.exec(workDate);

  if (value) {
    const stringAsNumber = Number(value[1]);
    return new Date(stringAsNumber).toISOString();
  }
}
