import React, {ImgHTMLAttributes} from 'react'

import icon from '../logo-v.png';

const EasyVoteIcon = ({ alt = "EasyVote", ...rest }: ImgHTMLAttributes<HTMLImageElement>) => (
  <img alt={alt} {...rest} src={icon}  />
)

export default EasyVoteIcon

