import React, { ReactNode, useEffect, useState } from "react";
import {getWorkSchedule} from "../fetchers";
import {
  CalendarDaysIcon,
  CalendarIcon, ChevronDownIcon,
  TableCellsIcon
} from "@heroicons/react/24/outline";
import { Flexor, Spinner } from "shared/src/components";
import { classNames } from "shared/src/utils/classNames";
import {useAppDispatch, useAppSelector} from '../hooks'
import { Link, useParams } from "wouter";
import WorkScheduleCalendarView from "./WorkScheduleCalendarView";
import WorkScheduleTableView from "./WorkScheduleTableView";
import PageEmptyState from "./PageEmptyState";
import { WorkSchedule } from 'pollworker/src/types/WorkSchedule';
import { Button } from "shared/src/components/ui/Button";
import { ConfirmationStatus } from "../enums/confirmationStatus";
import {selectCurrentUserWorkSchedules, setCurrentUserWorkSchedules} from "../state/schedule";

export function renderDataAttribute(data: any) {
  return (<span className={classNames(!data ? 'italic text-gray-600' : '')}>{data ? data : 'Not specified'}</span>);
}

export default function WorkSchedules() {
  const dispatch = useAppDispatch();
  const selectedElectionId = useAppSelector((state) => state.election.electionId);
  const workSchedules = useAppSelector(selectCurrentUserWorkSchedules);
  const [loadingWorkSchedules, setLoadingWorkSchedules] = useState<boolean>(false);
  const [workScheduleId, setWorkScheduleId] = useState<string>('');
  const [showWorkScheduleConfirmation, setShowWorkScheduleConfirmation] = useState<boolean>(false);
  const [isConfirmSelected, setIsConfirmSelected] = useState<boolean>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const { viewName = 'table' } = useParams();

  useEffect(() => {
    if (!selectedElectionId) return;

    loadWorkSchedules();
  }, [selectedElectionId]);

  function loadWorkSchedules() {
    setLoadingWorkSchedules(true);

    getWorkSchedule(selectedElectionId).then((response: WorkSchedule[]) => {
      dispatch(setCurrentUserWorkSchedules(response));
    })
    .catch(() => {
      setErrorMessage('Could not load work schedules');
    })

    .finally(() => setLoadingWorkSchedules(false));
  }

  function openScheduleConfirmationModal(workSchedule: WorkSchedule) {
    setWorkScheduleId(workSchedule.Id);
    setShowWorkScheduleConfirmation(true);
  }

  function closeScheduleConfirmationModal() {
    setShowWorkScheduleConfirmation(false);
    loadWorkSchedules();
  }

  function openConfirmationDialog(workSchedule: WorkSchedule, confirm?: boolean) {
    setIsConfirmSelected(confirm);
    openScheduleConfirmationModal(workSchedule);
  }

  return (
    <div className="mt-2">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900 mb-4">Work Schedule</h1>
          <div className='lg:flex justify-between items-center'>
            <div className='space-y-2 text-sm text-gray-700'>
              <p>Your work schedule is displayed here. You are currently viewing the schedule in grid format.</p>
              <p>Use the "Change" or "Respond" buttons below to alert staff which scheduled times you are able to fulfill.</p>
            </div>

            <Flexor justify='center' className=' mt-4 lg:mt-0'>
              <Link to='/work_schedule'>
                <Button className={classNames(viewName === 'table' ? '' : 'bg-gray-200 hover:bg-gray-300 text-gray-900', 'rounded-r-none w-full lg:w-auto')}>
                    <div className='flex justify-center items-center w-full space-x-2'>
                    <TableCellsIcon className='h-5 w-5' />
                    <div>Table</div>
                  </div>
                </Button>
              </Link>
              <Link to='/work_schedule/calendar'>
                <Button className={classNames(viewName === 'table' ? 'bg-gray-200 hover:bg-gray-300 text-gray-900' : '', 'rounded-l-none w-full lg:w-auto')}>
                  <div className='flex justify-center items-center w-full space-x-2'>
                    <CalendarDaysIcon className='h-5 w-5' />
                    <div>Calendar</div>
                  </div>
                </Button>
              </Link>
            </Flexor>
          </div>
        </div>
      </div>

      <div className="-mx-4 mt-8 sm:-mx-0">
        {
          loadingWorkSchedules && !workSchedules.length ? (
            <div className='w-full flex justify-center items-center h-24 my-24 space-x-2'>
              <Spinner show />
              <span className='text-lg'>Loading work schedules...</span>
            </div>
          ) : null
        }
        {
          !loadingWorkSchedules && !workSchedules.length ? (
            <PageEmptyState icon={CalendarIcon} errorMessage={errorMessage} emptyMessageHeader='No work schedules' emptyMessageSubHeader='Check back soon to keep up-to-date with your schedule.' reload={loadWorkSchedules} />
          ) : null
        }
        {
          workSchedules.length ? (
            <div className={classNames(loadingWorkSchedules ? 'opacity-25' : '', 'transition-opacity')}>
              { viewName === 'table' ? <WorkScheduleTableView workSchedules={workSchedules} /> : null }
              { viewName === 'calendar' ? <WorkScheduleCalendarView workSchedules={workSchedules} /> : null }
            </div>
          ) : null
        }
      </div>
    </div>
  )
}

export function getStatusLabel(workSchedule: WorkSchedule): ReactNode {
  switch (workSchedule.ConfirmationStatus) {
  case ConfirmationStatus.Confirmed:
    return (
      <>
        <div className='mr-0.5 rounded-full bg-green-500 h-3 w-3'></div>
        <span>Confirmed</span>
      </>
    )
  case ConfirmationStatus.Declined:
    return (
      <>
        <div className='mr-0.5 rounded-full bg-red-500 h-3 w-3'></div>
        <span>Declined</span>
      </>
    )
  case ConfirmationStatus["No Response"]:
    return (
      <>
        <div className='mr-0.5 rounded-full bg-gray-500 h-3 w-3'></div>
        <span>No response</span>
      </>
    )
  default:
    return null;
  }
}
