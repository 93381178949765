import {createAction} from "@reduxjs/toolkit";

export const SELECTIONS_ACTION_TYPES = {
  SET_SELECTED_MAIN_COMPONENT: 'SET_SELECTED_MAIN_COMPONENT',
  SET_SELECTED_SUB_COMPONENT: 'SET_SELECTED_SUB_COMPONENT',
  HIDE_COOKIE_ALERT: 'HIDE_COOKIE_ALERT',
};
export const setSelectedMainComponent = createAction<string>(SELECTIONS_ACTION_TYPES.SET_SELECTED_MAIN_COMPONENT);
export const setSelectedSubComponent = createAction<string>(SELECTIONS_ACTION_TYPES.SET_SELECTED_SUB_COMPONENT);
export const showHideCookieAlert = createAction<boolean>(SELECTIONS_ACTION_TYPES.HIDE_COOKIE_ALERT);
