import React, {useState} from "react";
import {verifyCreateCredentials} from "../fetchers";
import {toast} from "react-toastify";
import {Button} from "shared/src/components/ui/Button";
import {UserCredentials} from "./Registration";

export function VerifyUserIdentity(verifyUserIdProps: { evUserId: string, setUserCredentials: Function, setProceedToNextStep: Function }) {
  const [userCredentialsForm, setUserCredentialsForm] = useState<UserCredentials>(
    { FirstName: '', LastName: '', VRNumber: '', });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserCredentialsForm({
      ...userCredentialsForm,
      [event.target.id]: event.target.value
    });
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>, evUserId: string, data: UserCredentials) {
    event.preventDefault();
    verifyCreateCredentials(evUserId, data).then((response) => {
      if (response === true) {
        verifyUserIdProps.setUserCredentials(data);
        verifyUserIdProps.setProceedToNextStep(true);
        return;
      }

      toast.error('Verification failed. Please try again.', {
        type: 'error', closeButton: false, autoClose: 2000,
      });

    });
  }

  return (
    <div className='flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8'>
      <form
        onSubmit={(event) => handleSubmit(event, verifyUserIdProps.evUserId, userCredentialsForm)}
        role="form"
      >
        <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md p-10 rounded-2xl shadow'>
          <h1 className="text-base font-semibold leading-6 text-gray-900 text-center">Verify User Identity</h1>
          <p className="mt-2 text-sm text-gray-700 text-center">
            Please enter your First Name, Last Name and VR Number.
          </p>
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8">
            <div className="col-span-1">
              <label htmlFor="FirstName" className="block text-sm font-medium leading-6 text-gray-900">
                First Name
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  id="FirstName"
                  value={userCredentialsForm?.FirstName}
                  onChange={handleChange}
                  required
                  placeholder='First name'
                  autoComplete="given-name"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ev-red sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="col-span-1">
              <label htmlFor="LastName" className="block text-sm font-medium leading-6 text-gray-900">
                Last Name
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  id="LastName"
                  value={userCredentialsForm?.LastName}
                  onChange={handleChange}
                  required
                  placeholder='Last name'
                  autoComplete="family-name"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ev-red sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="col-span-1">
              <label htmlFor="VRNumber" className="block text-sm font-medium leading-6 text-gray-900">
                VR Number
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  id="VRNumber"
                  value={userCredentialsForm?.VRNumber}
                  onChange={handleChange}
                  required
                  placeholder='VR Number'
                  autoComplete="off"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ev-red sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
          <div className='sm:flex mt-10 justify-end col-span-full'>
            <div className='flex flex-col justify-center'>
              <Button type="submit">
                Submit
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}
