import Footer from "./Footer";
import Header from "./Header";
import React, { ReactNode } from "react";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {HiddenLink} from "shared/src/components/Accessibility";

export default function Container({children}: {children: ReactNode}) {
  return (
    <div className='w-full'>
      <HiddenLink href={'#main'}>Skip to main content</HiddenLink>
      <Header/>
      <main id={'main'} role="main">
      <div className='px-4 lg:px-16'>
        <div className='mx-auto max-w-7xl'>
          {children}
        </div>
      </div>
      </main>
      <Footer/>
      <ToastContainer position='top-center'/>
    </div>
  )
}
