import React from 'react';
import {useLocation} from 'wouter';
import {useAppSelector} from '../hooks'
import {LinkAnchor} from 'shared/src/components/Links';
import {SectionHeading, SectionSubHeading} from 'shared/src/components/SectionHeading';
import useIsLoggedIn from './selectors/useIsLoggedIn';
import {useLogOut} from "../hooks/useLogOut";

export default function PollworkerHome() {
  const currentCustomer = useAppSelector((state) => state.currentCustomer);
  const currentUser = useAppSelector((state) => state.currentPollworkerUser);
  const [, navigate] = useLocation();
  const isLoggedIn = useIsLoggedIn();
  const logOut = useLogOut();

  return (
    <div className="overflow-hidden bg-white px-6 py-16 lg:px-8 xl:py-36">
      <div className="mx-auto max-w-max lg:max-w-7xl">
        <div className="relative z-10 mb-8 md:mb-2 md:px-6">
          <div className="max-w-prose text-base lg:max-w-none">
            <SectionHeading
              className="font-semibold leading-6 text-ev-blue"
              role="heading"
            >
              Pollworker by EasyVote
            </SectionHeading>
            <p className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
              {currentCustomer.Customer.Name}
            </p>
          </div>
        </div>
        <div className="relative">
          <svg
            className='absolute right-0 top-0 -mr-20 -mt-20 hidden md:block md:[overflow-anchor:none]'
            width={404}
            height={384}
            fill='none'
            viewBox='0 0 404 384'
            aria-hidden='true'
          >
            <defs>
              <pattern
                id='95e8f2de-6d30-4b7e-8159-f791729db21b'
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits='userSpaceOnUse'
              >
                <rect x={0} y={0} width={4} height={4} className='text-gray-200' fill='currentColor'/>
              </pattern>
            </defs>
            <rect width={404} height={384} fill='url(#95e8f2de-6d30-4b7e-8159-f791729db21b)'/>
          </svg>
          <svg
            className='absolute bottom-0 left-0 -mb-20 -ml-20 hidden md:block md:[overflow-anchor:none]'
            width={404}
            height={384}
            fill="none"
            viewBox='0 0 404 384'
            aria-hidden='true'
          >
            <defs>
              <pattern
                id='7a00fe67-0343-4a3c-8e81-c145097a3ce0'
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits='userSpaceOnUse'
              >
                <rect x={0} y={0} width={4} height={4} className='text-gray-200' fill='currentColor'/>
              </pattern>
            </defs>
            <rect width={404} height={384} fill='url(#7a00fe67-0343-4a3c-8e81-c145097a3ce0)'/>
          </svg>
          {!isLoggedIn ?
            <div className='relative md:bg-white md:p-6'>
              <div className='lg:grid lg:grid-cols-2 lg:gap-6 justify-center'>
                <div className='prose prose-lg prose-indigo space-y-4 text-gray-500 lg:max-w-none'>
                  <SectionSubHeading role="heading">General Information</SectionSubHeading>
                  <p>
                    If you have already registered with us and have your username and password, then please
                    use our <LinkAnchor onClick={() => navigate('/login')}>login page</LinkAnchor>.
                  </p>
                </div>
                <div className='prose prose-lg prose-indigo mt-6 text-gray-500 lg:mt-0 space-y-4'>
                  <SectionSubHeading role="heading">Forgot your password?</SectionSubHeading>
                  <p>
                    If you need to setup your password for the first time, or if you have simply forgotten your
                    password, you can easily reset it using our <LinkAnchor onClick={() => navigate('/login/forgot')}>Set/Reset
                    Password</LinkAnchor> page.
                  </p>
                  <p>
                    If you have a cell phone attached to your account, a text message will be sent with a code for you
                    to reset your password. If you don't have a cell phone attached to your account, an email will be
                    sent with the code.
                  </p>
                </div>
              </div>
            </div>
            :
            <div className='relative md:bg-white md:p-6'>
              <div className='lg:grid lg:grid-cols-2 lg:gap-16'>
                <div className='prose prose-lg prose-indigo space-y-4 text-gray-900 pt-[24px] max-w-sm'>
                  <p className='text-lg leading-6 font-semibold'>
                    Welcome, {currentUser?.FirstName} {currentUser?.LastName}!
                  </p>
                    <SectionSubHeading>
                      <LinkAnchor onClick={() => navigate('/news')}>News</LinkAnchor>
                    </SectionSubHeading>
                    Find the latest news and updates.
                    <SectionSubHeading>
                      <LinkAnchor onClick={() => navigate('/work_schedule')}>Work Schedule</LinkAnchor>
                    </SectionSubHeading>
                    See when you are scheduled to work, and alert staff which scheduled times you are able to fulfill.
                    <SectionSubHeading>
                      <LinkAnchor onClick={() => navigate('/classes/required')}>Required Classes</LinkAnchor>
                    </SectionSubHeading>
                    Register for required classes. These classes are not optional.
                    <SectionSubHeading>
                      <LinkAnchor onClick={() => navigate('/classes/scheduled')}>Scheduled Classes</LinkAnchor>
                    </SectionSubHeading>
                    Manage your scheduled classes.
                    <SectionSubHeading>
                      <LinkAnchor onClick={() => navigate('/documents')}>Documents</LinkAnchor>
                    </SectionSubHeading>
                    Access important documents and information.
                </div>
                <div className='prose prose-lg prose-indigo space-y-4 text-gray-900 max-w-sm'>
                  <div className='lg:pt-[48px]'/>
                    <SectionSubHeading>
                      <LinkAnchor onClick={() => navigate('/profile')}>Profile</LinkAnchor>
                    </SectionSubHeading>
                    Make changes to your personal information.
                    <SectionSubHeading>
                      <LinkAnchor onClick={logOut}>Sign Out</LinkAnchor>
                    </SectionSubHeading>
                    Please remember to sign out when you are finished, especially when using a public computer.
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  )
}
