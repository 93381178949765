import React, {useMemo, useRef, useState} from "react";
import {useLocation} from "wouter";
import {useAppDispatch} from "../hooks";
import {debounce} from "lodash";
import {getCurrentUser, login, updateLoginCredentials, verifyUserIdUnique} from "../fetchers";
import {toast} from "react-toastify";
import {setCurrentPollworkerUser} from "../state/actions";
import {Button} from "shared/src/components/ui/Button";
import {FormProps, LoginCredentials, UserCredentials} from "./Registration";

export function CreateLoginCredentials( createLoginProps: { evUserId: string, userCredentials: UserCredentials }) {
  const [loginCredentials, setLoginCredentials] = useState<LoginCredentials>(
    { UserName: '', Password: '', CredentialsInfo: createLoginProps.userCredentials });
  const [props, setProps] = useState<FormProps>(
    { passwordIsValid: false, passwordMatches: true });
  const [, navigate] = useLocation();
  const dispatch = useAppDispatch();
  const userNameRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const confirmPasswordRef = useRef<HTMLInputElement>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoginCredentials({
      ...loginCredentials,
      [event.target.id]: event.target.value
    });
    setProps((latestProps) => {
      return {...latestProps, checkingUserName: true, userNameIsUnique: true}
    });
    userNameValidation();
    passwordValidation();
  }

  const userNameValidation = useMemo(() => {
    return debounce(() => {

      if (!userNameRef.current) return;

      verifyUserIdUnique(userNameRef.current.value).then((response) => {
        if (response === false) {
          setProps((latestProps) => {
            return {...latestProps, userNameIsUnique: false, checkingUserName: false}
          });
          return;
        }

        setProps((latestProps) => {
          return {...latestProps, userNameIsUnique: true, checkingUserName: false}
        });
      });
      return;
    }, 750);
  }, []);

  function passwordValidation() {
    const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/g;

    if (!passwordRef.current || !confirmPasswordRef.current) return;

    if (!passwordRegex.test(passwordRef.current.value)) {
      setProps((latestProps) => {
        return {...latestProps, passwordIsValid: false} });

    } else if (passwordRef.current.value !== confirmPasswordRef.current.value) {
      setProps((latestProps) => {
        return {...latestProps, passwordMatches: false} });

    } else {
      setProps((latestProps) => {
        return {...latestProps, passwordMatches: true, passwordIsValid: true}
      });
    }
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>, evUserId: string, loginCredentials: LoginCredentials) {
    event.preventDefault();

    if (!props.passwordMatches) {
      toast.error('Your passwords do not match. Please try again.', {
        type: 'error', closeButton: false, autoClose: 2000,
      });
      return;
    }

    if (!props.passwordIsValid) {
      toast.error('Your password is not valid. Please try again.', {
        type: 'error', closeButton: false, autoClose: 2000,
      });
      return;
    }

    if (!props.userNameIsUnique) {
      toast.error('This username is already in use. Please try again.', {
        type: 'error', closeButton: false, autoClose: 2000,
      });
      return;
    }

    updateLoginCredentials(evUserId, loginCredentials).then((response) => {
      if (!response) {
        toast.error('An error occurred. Please try again.', {
          type: 'error', closeButton: false, autoClose: 2000,
        });
        return;
      }

      login(loginCredentials.UserName, loginCredentials.Password).then(async ([response, data]) => {
        if (!response) {
          toast.error('An error occurred. Please try again.', {
            type: 'error', closeButton: false, autoClose: 2000,
          });
          return;
        }

        window.localStorage.setItem('ZumoToken', data.AuthenticatedUser.ZumoToken);
        window.localStorage.setItem('CurrentUser', data.AuthenticatedUser.UserId);

        toast.success('Registration successful! You are now logged in.', {
          type: 'success', closeButton: false, autoClose: 2000,
        });

        const resp = await getCurrentUser(data.AuthenticatedUser.UserId);

        dispatch(setCurrentPollworkerUser(resp));
        navigate('~/news');
      });

    });
  }

  return (
    <div className='flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8'>
      <form
        onSubmit={(event) => handleSubmit(event, createLoginProps.evUserId, loginCredentials)}
        role="form"
      >
        <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md p-10 rounded-2xl shadow'>
          <div>
            <h1 className="text-base font-semibold leading-6 text-gray-900 text-center">Create User Login
              Credentials</h1>
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-4">
              <div className="col-span-1">
                <h2 className="text-base font-semibold leading-6 text-gray-900">User ID</h2>
                <p className="mt-2 text-sm text-gray-700">
                  Please enter a User ID.
                </p>
              </div>
              <div className="col-span-1">
                <label htmlFor="UserName" className="block text-sm font-medium leading-6 text-gray-900">
                  Username
                </label>
                <div className="mt-2">
                  <input
                    ref={userNameRef}
                    autoComplete="off"
                    type="text"
                    id="UserName"
                    value={loginCredentials?.UserName}
                    onChange={handleChange}
                    required
                    placeholder='Username'
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ev-red sm:text-sm sm:leading-6"
                  />
                  {props.checkingUserName &&
                      <div className='absolute text-xs font-semibold text-ev-red'>Checking username
                          availability...</div>}
                  {props.userNameIsUnique && !props.checkingUserName && loginCredentials.UserName.length > 0 &&
                      <div className='absolute text-xs font-semibold text-green-600'>Username is available</div>}
                  {props.userNameIsUnique === false &&
                      <div className='absolute text-xs font-semibold text-ev-red' role='alert'>This username is already
                          in use</div>}
                </div>
              </div>
              <div className="col-span-1">
                <h2 className="text-base font-semibold leading-7 text-gray-900">Password</h2>
                <p className="mt-2 text-sm text-gray-700">
                  Please create a password and confirm it. Password must contain at least one lowercase letter, one
                  uppercase letter, one number and one special character.
                </p>
              </div>
              <div className="col-span-1">
                <label htmlFor="Password" className="block text-sm font-medium leading-6 text-gray-900">
                  Password
                </label>
                <div className="mt-2">
                  <input
                    ref={passwordRef}
                    autoComplete="new-password"
                    type="password"
                    id="Password"
                    onChange={handleChange}
                    required
                    placeholder='Enter Password'
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ev-red sm:text-sm sm:leading-6"
                  />
                  {!props.passwordMatches &&
                      <div className='absolute text-xs font-semibold text-ev-red'>Password does not match</div>}
                  {!props.passwordIsValid && props.passwordMatches && loginCredentials.Password.length > 0 &&
                      <div className='absolute text-xs font-semibold text-ev-red'>Password must meet requirements</div>}
                </div>
              </div>
              <div className="col-span-1">
                <label htmlFor="confirmPassword" className="block text-sm font-medium leading-6 text-gray-900">
                  Confirm Password
                </label>
                <div className="mt-2">
                  <input
                    ref={confirmPasswordRef}
                    autoComplete="new-password"
                    type="password"
                    id="confirmPassword"
                    onChange={handleChange}
                    required
                    placeholder='Confirm Password'
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ev-red sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='sm:flex mt-10 justify-end col-span-full'>
            <div className='flex flex-col justify-center'>
              <Button type="submit">
                Submit
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}
