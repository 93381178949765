import { fetchApi, mapDateString } from './index';
import { PollworkerTimeclockEntry } from '../types';

export const clockInSchedule = async (scheduleId: string): Promise<PollworkerTimeclockEntry> => {
  return fetchApi(`/pollworker/timeclock/clockin/${scheduleId}`, {
    method: 'POST'
  }).then(async response => (await response.json()) as PollworkerTimeclockEntry)
  .then((entry: PollworkerTimeclockEntry) => ({
    ...entry,
    TimeIn: mapDateString(entry.TimeIn),
    TimeOut: mapDateString(entry.TimeOut),
  }));
};

export const clockOutSchedule = async (scheduleId: string): Promise<PollworkerTimeclockEntry> => {
  return fetchApi(`/pollworker/timeclock/clockout/${scheduleId}`, {
    method: 'POST'
  }).then(async response => (await response.json()) as PollworkerTimeclockEntry)
  .then((entry: PollworkerTimeclockEntry) => ({
    ...entry,
    TimeIn: mapDateString(entry.TimeIn),
    TimeOut: mapDateString(entry.TimeOut),
  }));
};
