import React from 'react'
import { ForgotPassword } from 'shared/src/components';
import { startPasswordReset } from '../fetchers'
import {useLocation} from "wouter";
import { SESSION_STORAGE_KEYS } from "../constants";

export default function ForgotPasswordScreen() {
  const [, navigate] = useLocation();

  const handleSuccessfulReset = (username: string) => {
    setTimeout(() => {
      window.sessionStorage.setItem(SESSION_STORAGE_KEYS.FORGOT_PASSWORD_USER_ID, username);
      navigate('~/login/reset');
    }, 2000);
  }

  return (
    <ForgotPassword startPasswordReset={startPasswordReset} onSuccessfulReset={handleSuccessfulReset} />
  )
}
