import { z } from "zod";
import { phoneRegEx } from "shared/src/utils/formatters";

const baseFormSchemaShape = {
  firstName: z.string().min(1, { message: "First name is required" }),
  lastName: z.string().min(1, { message: "Last name is required" }),
  streetAddress: z.string().min(1, { message: "Street address is required" }),
  streetAddress2: z.string().optional(),
  city: z.string().min(1, { message: "City is required" }),
  region: z.string().min(1, { message: "State / Province is required" }),
  postalCode: z.string().min(1, { message: "ZIP / Postal code is required" }),
  email: z.string().email({ message: "Invalid email address" }),
  cellPhone: z.string().optional(),
  homePhone: z.string().optional(),
  preferredContact: z.enum(['cellPhone', 'homePhone']),
  partyAffiliation: z.string().optional(),
};

export function generateFormSchema(additionalSchema: any = {}) {

  return z
    .object({ ...baseFormSchemaShape, ...additionalSchema })
    .superRefine((data, ctx) => {

      if (data.preferredContact === 'cellPhone' && (!data.cellPhone || data.cellPhone.trim() === '')) {
        ctx.addIssue({
          path: ['cellPhone'],
          message: 'Cell Phone is required when it is set as the preferred contact method',
          code: z.ZodIssueCode.custom,
        });
      }

      if (data.cellPhone && !phoneRegEx.test(data.cellPhone.trim())) {
        ctx.addIssue({
          path: ['cellPhone'],
          message: 'Cell phone must have 10 digits',
          code: z.ZodIssueCode.custom,
        });
      }

      if (data.preferredContact === 'homePhone' && (!data.homePhone || data.homePhone.trim() === '')) {
        ctx.addIssue({
          path: ['homePhone'],
          message: 'Home Phone is required when it is set as the preferred contact method',
          code: z.ZodIssueCode.custom,
        });
      }

      if (data.homePhone && !phoneRegEx.test(data.homePhone.trim())) {
        ctx.addIssue({
          path: ['homePhone'],
          message: 'Home phone must have 10 digits',
          code: z.ZodIssueCode.custom,
        });
      }
    });
}

export default generateFormSchema();
