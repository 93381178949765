import React, {useCallback, useMemo, useState} from 'react';
import { PollworkerTimeclockEntry, WorkSchedule } from 'pollworker/src/types';
import { Button } from 'shared/src/components/ui';
import { ClockIcon, EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline';
import { classNames, phoneNumberFormatter } from 'shared/src/utils';
import dayjs from 'dayjs';
import {ScreenReaderOnly, Spinner } from 'shared/src/components';
import { SimpleTippyContent } from 'shared/src/components/TippyContent';
import { renderDataAttribute } from './WorkSchedules';
import Timeclock from './Timeclock';

export default PollworkerScheduleCard;

export interface PollworkerScheduleCardProps {
  className?: string | undefined;
  pollworkerSchedule: WorkSchedule;
  onClockIn: (pollworkerSchedule: WorkSchedule) => Promise<void>;
  onClockOut: (pollworkerSchedule: WorkSchedule) => Promise<void>;
}

function PollworkerScheduleCard({ className, pollworkerSchedule, onClockIn, onClockOut } : PollworkerScheduleCardProps) {
  const [loading, setLoading] = useState<boolean>(false);

  const mostRecentTimeclockEntry = useMemo(
    () => pollworkerSchedule?.TimeclockEntries?.filter(x => x.TimeIn)
            .sort((a: PollworkerTimeclockEntry, b: PollworkerTimeclockEntry) => (a.TimeIn as string) > (b.TimeIn as string) ? 1 : -1)
            .pop(),
      [pollworkerSchedule]
  );

  const wrongDate = useMemo(
    () => {
      const scheduledDate = pollworkerSchedule.WorkDateExact.split('T')[0];

      return scheduledDate != dayjs().format('YYYY-MM-DD');
    },
    [pollworkerSchedule]
  );

  const handleClockIn = useCallback(() => {
    setLoading(true);

    onClockIn(pollworkerSchedule).finally(() => {
      setLoading(false);
    });
  }, [onClockIn]);

  const handleClockOut = useCallback(() => {
    setLoading(true);

    onClockOut(pollworkerSchedule).finally(() => {
      setLoading(false);
    });
  }, [onClockOut]);

  const [clockTime, clockedOut, clockedInFormatted, clockedOutFormatted, actionLabel, borderColor] = useMemo(() => {
    let borderColor = 'border-neutral-400';
    const clockedOut = !mostRecentTimeclockEntry || !!mostRecentTimeclockEntry.TimeOut;
    let clockTime: number;

    if (!mostRecentTimeclockEntry) {
      const startTime = dayjs(pollworkerSchedule.StartTime, "hh:mm A")
      clockTime = dayjs(pollworkerSchedule.WorkDateExact)
        .add(startTime.hour(), 'hours')
        .add(startTime.minute())
        .toDate().getTime();
    } else {
      clockTime = parseDateString(mostRecentTimeclockEntry?.TimeIn as string).toDate().getTime();
    }

    if (mostRecentTimeclockEntry && !mostRecentTimeclockEntry.TimeOut) {
      borderColor = 'border-success';
    } else if (mostRecentTimeclockEntry) {
      borderColor = 'border-warning';
    } else if (dayjs().toDate().getTime() > clockTime) {
      borderColor = 'border-error';
    }

    return [
      clockTime,
      clockedOut,
      mostRecentTimeclockEntry?.TimeIn
        ? parseDateString(mostRecentTimeclockEntry?.TimeIn).format('LTS')
        : '',
      mostRecentTimeclockEntry?.TimeOut
        ? parseDateString(mostRecentTimeclockEntry?.TimeOut).format('LTS')
        : '',
      wrongDate
        ? 'Clock In/Out Unavailable'
        : (!mostRecentTimeclockEntry || !!mostRecentTimeclockEntry.TimeOut) ? 'Clock In' : 'Clock Out',
      borderColor
    ]
  }, [mostRecentTimeclockEntry]);

  return (
    <div className={classNames(className, borderColor, 'flex flex-col w-96 border-4 p-4 gap-2 h-fit')}>
      <div className="flex flex-row justify-between">
        <div className="flex flex-row gap-2 items-center">
          <ClockIcon className="size-6" />
          <div className="text-sm">Clocked {clockedOut ? 'Out' : 'In'}</div>
        </div>
        <Timeclock scheduledTimeUTC={clockTime} isClockedIn={!clockedOut} />
      </div>
      <SimpleTippyContent content={pollworkerSchedule?.PollworkerFullName}>
        <div className="text-2xl truncate">{pollworkerSchedule?.PollworkerFullName}</div>
      </SimpleTippyContent>
      <dd className='flex items-center basis-full sm:basis-1/3 min-w-0'>
        <span className='pr-2'>
          <PhoneIcon className='h-5 w-5'/>
          <ScreenReaderOnly>Phone</ScreenReaderOnly>
        </span>
        <span className='truncate'>
          {renderDataAttribute(phoneNumberFormatter({value: pollworkerSchedule?.ContactNumber}))}
        </span>
      </dd>
      <dd className='flex items-center basis-full sm:basis-2/3 min-w-0'>
        <span className='pr-2'>
          <EnvelopeIcon className='h-5 w-5'/>
          <ScreenReaderOnly>Email</ScreenReaderOnly>
        </span>
        <SimpleTippyContent disabled={!pollworkerSchedule?.ContactEmail || pollworkerSchedule?.ContactEmail?.length < 30} content={pollworkerSchedule?.ContactEmail as string}>
          <span className='truncate'>
            {renderDataAttribute(pollworkerSchedule?.ContactEmail)}
          </span>
        </SimpleTippyContent>
      </dd>
      <div className="text-sm">
        {!clockedOut && <span>Clocked in at: {clockedInFormatted}</span>}
        {clockedOut && <span className="italic">{mostRecentTimeclockEntry ? `Clocked out at ${clockedOutFormatted}` : `Schedule to start at: ${pollworkerSchedule.StartTime}`}</span>}
      </div>
      <div className="flex flex-row">
        <div className="grow">
          <Button disabled={loading || wrongDate} className="w-full" variant="secondary" onClick={() => clockedOut ? handleClockIn() : handleClockOut()}>
            <Spinner show={loading} />
            {!loading && actionLabel}
          </Button>
        </div>
      </div>
    </div>
  );
}

function parseDateString(dateString: string) {
  return dayjs(Number.parseInt(dateString))
}
