import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useAppSelector, useIsFeatureFlagEnabled } from '../hooks';
import { Link, useLocation } from 'wouter';
import { getEmployeeImage } from '../fetchers';
import {
  AcademicCapIcon, ChevronDownIcon, ClockIcon,
  DocumentChartBarIcon, HomeIcon,
  NewspaperIcon, UserCircleIcon, XMarkIcon
} from '@heroicons/react/24/outline';
import { Flexor, TippyContent } from 'shared/src/components';
import { phoneNumberFormatter } from 'shared/src/utils/formatters';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon } from '@heroicons/react/24/solid';
import { classNames } from 'shared/src/utils/classNames';
import DomainLogo from './DomainLogo';
import Tippy from '@tippyjs/react';
import { HiddenLink, ScreenReaderOnly } from 'shared/src/components/Accessibility';
import { Button } from "shared/src/components/ui/Button";
import ElectionList from './ElectionList';
import { useLogOut } from '../hooks/useLogOut';
import { selectCurrentUserIsManagerInElection } from 'pollworker/src/state/schedule';

export default function HeaderAuthed() {
  const timeclockEnabled = useIsFeatureFlagEnabled('pollworker-timeclock');
  const currentUserIsManagerForElection = useAppSelector(selectCurrentUserIsManagerInElection);
  const currentUser = useAppSelector((state) => state.currentPollworkerUser);
  const currentCustomer = useAppSelector((state) => state.currentCustomer);
  const [employeeImage, setEmployeeImage] = useState<string>();
  const [location, navigate] = useLocation();
  const logOut = useLogOut();

  useEffect(() => {
    createEmployeeImageURL();
  }, [currentCustomer, currentUser]);

  const tabs = useMemo(() => {
    return [
      { current: location === '/', icon: HomeIcon, name: 'Home', path: '/' },
      { current: location === '/news', icon: NewspaperIcon, name: 'News', path: '/news' },
      (timeclockEnabled && currentUserIsManagerForElection) ?
        { current: location.startsWith('/work_schedule') || location.endsWith('/timeclock'), icon: ClockIcon, name: 'Schedule', path: '',
          nestedPath: [
            {current: location.endsWith('/work_schedule'), name: 'Work Schedule', path:'/work_schedule', userHasAccess: true },
            {current: location.endsWith('/timeclock'), name: 'Timeclock', path:'/schedule/timeclock', userHasAccess: true },
          ]
        } : { current: location.startsWith('/work_schedule'), icon: ClockIcon, name: 'Schedule', path: '/work_schedule' },
      { current: location.startsWith('/classes'), icon: AcademicCapIcon, name: 'Classes', path: '',
        nestedPath: [
          { current: location.endsWith('/required'), name: 'Required', path: '/classes/required', userHasAccess: true },
          { current: location.endsWith('/scheduled'), name: 'Scheduled', path: '/classes/scheduled', userHasAccess: true },
        ]
      },
      { current: location === '/documents', icon: DocumentChartBarIcon, name: 'Documents', path: '/documents' },
    ];
  }, [timeclockEnabled, currentUserIsManagerForElection, location, currentUser]);

  function createEmployeeImageURL() {
    if (!currentCustomer || !currentUser) return;

    getEmployeeImage(currentCustomer.Customer.Id, currentUser.EVUserId).then((response) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        reader.result && setEmployeeImage(`data:image/png;base64,${reader.result.toString().split(',').pop()}`);
      }

      reader.readAsDataURL(response);
    })
    .catch(() => {
      // Do nothing, no image
    });
  }

  return (
    <div>
      <HiddenLink href={'#main-nav'}>Skip to main navigation</HiddenLink>
      {
        currentUser?.Customer ? (
          <div className='px-4 lg:px-16 text-xs border-b border-gray-100 py-1'>
            <Flexor className='mx-auto max-w-7xl'>
              <div>{currentUser.Customer.Name}</div>
              <div><a href={`tel:${currentUser.Customer.Phone}`}
                      aria-label='telephone number'>{phoneNumberFormatter({value: currentUser.Customer.Phone})}</a>
              </div>
            </Flexor>
          </div>
        ) : null
      }
      <Disclosure as='nav' id='main-nav' role='navigation' className='bg-white shadow px-4 lg:px-16'>
        {({open, close}) => (
          <>
            <div className="mx-auto max-w-7xl py-2">
              <div className='relative flex h-16 justify-between'>
                <div className='absolute inset-y-0 left-0 flex items-center sm:hidden'>
                  {/* Mobile menu button */}
                  <Disclosure.Button as={Button} variant='icon' className='mr-1.5'>
                    {
                      open ? (
                        <XMarkIcon className='block shrink-0 h-6 w-6' aria-hidden='true'/>
                      ) : (
                        <span>
                          <Bars3Icon className='block shrink-0 h-6 w-6' aria-hidden='true'/>
                          <ScreenReaderOnly>Open main menu</ScreenReaderOnly>
                        </span>
                      )
                    }
                  </Disclosure.Button>
                  <DomainLogo authed />
                </div>
                <div className='flex flex-1 items-center justify-start sm:items-stretch sm:justify-start'>
                  <div className='flex-shrink-0 items-center hidden lg:flex'>
                    <DomainLogo authed />
                  </div>
                  <div className='hidden lg:ml-6 sm:flex space-x-2 lg:space-x-8'>
                    {
                      tabs.map((tab) => (
                        tab.nestedPath ? (
                          <span key={tab.name}>
                            <Menu>
                              <Menu.Button
                                key={tab.name}
                                className={classNames(tab.current
                                    ? 'border-ev-blue text-gray-900'
                                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                  'h-full inline-flex items-center border-b-2 pt-1 px-1 text-sm font-medium'
                                )}
                              >
                                {tab.name}
                                <ChevronDownIcon
                                    className="-mr-1 ml-2 h-5 w-5 text-violet-300 hover:text-violet-400"
                                    aria-hidden="true"
                                />
                              </Menu.Button>
                              <Transition
                                  as={Fragment}
                                  enter='transition ease-out duration-200'
                                  enterFrom='transform opacity-0 scale-50'
                                  enterTo='transform opacity-100 scale-100'
                                  leave='transition ease-in duration-75'
                                  leaveFrom='transform opacity-100 scale-100'
                                  leaveTo='transform opacity-0 scale-95'
                              >
                                <Menu.Items
                                      key={tab.name}
                                      className='absolute z-10 w-auto origin-top rounded-md bg-white py-1 shadow-lg focus:outline-none'
                                >
                                  {
                                    tab.nestedPath?.map((nestedTab) => (
                                      nestedTab.userHasAccess ? (<Menu.Item key={nestedTab.name}>
                                      {({active}) => (
                                        <button
                                          key={nestedTab.name}
                                          className={classNames(active ? 'bg-gray-100' : '', 'w-full text-left block px-4 py-2 text-sm text-gray-700')}
                                          onClick={() => { navigate(nestedTab.path); }}
                                        >
                                          {nestedTab.name}
                                        </button>
                                      )}
                                    </Menu.Item>) : null
                                    ))
                                  }
                                </Menu.Items>
                              </Transition>
                            </Menu>
                        </span>
                        ) : (
                          <Link
                            key={tab.name}
                            href={tab.path}
                            className={classNames(tab.current
                                ? 'border-ev-blue text-gray-900'
                                : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                              'h-full inline-flex items-center border-b-2 pt-1 px-1 text-sm font-medium'
                            )}
                          >
                            {tab.name}
                          </Link>
                        )
                      ))
                    }
                  </div>
                </div>
                <Flexor>
                  <div className='hidden sm:inline px-4'>
                    <ElectionList/>
                  </div>
                  <div className='absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:pr-0'>
                    <Menu as='div' className='relative ml-3'>
                      <div>
                        <Tippy content={<TippyContent>Profile and Settings</TippyContent>}>
                          <Menu.Button
                            className='relative flex rounded-full bg-white text-sm ring-4 ring-ev-blue hover:ring-ev-blue/60
                            hover:ring-4 focus:outline-none focus:ring-offset-2 focus:ring-ev-blue'>
                            <span className='absolute -inset-1.5'/>
                            <ScreenReaderOnly>Open user menu,</ScreenReaderOnly>
                            {
                              employeeImage ? (
                                <img
                                  className='h-8 w-8 rounded-full'
                                  src={employeeImage}
                                  alt='Profile'
                                />
                              ) : (
                                <UserCircleIcon className='h-8 w-8'/>
                              )
                            }
                          </Menu.Button>
                        </Tippy>
                      </div>
                      <Transition
                        as={Fragment}
                        enter='transition ease-out duration-200'
                        enterFrom='transform opacity-0 scale-95'
                        enterTo='transform opacity-100 scale-100'
                        leave='transition ease-in duration-75'
                        leaveFrom='transform opacity-100 scale-100'
                        leaveTo='transform opacity-0 scale-95'
                      >
                        <Menu.Items
                          className='absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                          <Menu.Item>
                            {({active}) => (
                              <button
                                onClick={() => navigate('/profile')}
                                className={classNames(active ? 'bg-gray-100' : '', 'w-full text-left block px-4 py-2 text-sm text-gray-700')}
                              >
                                Profile
                              </button>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({active}) => (
                              <button
                                onClick={logOut}
                                className={classNames(active ? 'bg-gray-100' : '', 'w-full text-left block px-4 py-2 text-sm text-gray-700')}
                              >
                                Sign out
                              </button>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </Flexor>
              </div>
            </div>

            <Disclosure.Panel className='sm:hidden'>
              <div className='space-y-1 pb-4 pt-2'>
                {
                  tabs.map((tab) => (
                    <div key={tab.path}>
                      {!tab.nestedPath ? (
                        <Disclosure.Button
                          as='a'
                          key={tab.path}
                          href={tab.path}
                          onClick={(event) => {
                            event.preventDefault();
                            close();
                            navigate(tab.path);
                          }}
                          className={classNames(tab.current ? 'block border-l-4 border-ev-blue bg-ev-blue/10 py-2 pl-3 pr-4 text-base font-medium text-ev-blue/80' : 'block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700')}
                        >
                          {tab.name}
                        </Disclosure.Button>
                      ) : null
                      }
                      {tab.nestedPath ? (
                        <span
                          key={tab.path}
                          className={classNames('block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500')}
                        >
                          {tab.name}
                        </span>
                      ) : null}
                      {
                        tab.nestedPath?.map((nestedTab) => (
                          <div key={nestedTab.path}>
                            <Disclosure.Button
                              as='a'
                              key={nestedTab.path}
                              href={nestedTab.path}
                              onClick={(event) => {
                                event.preventDefault();
                                close();
                                navigate(nestedTab.path);
                              }}
                              className={classNames(nestedTab.current ? 'block border-l-4 ml-8 border-ev-blue bg-ev-blue/10 py-2 pl-3 pr-4 text-base font-medium text-ev-blue/80' : 'block border-l-4 ml-8 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700')}
                            >
                              {nestedTab.name}
                            </Disclosure.Button>
                          </div>
                        ))
                      }
                    </div>

                  ))
                }
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <Flexor className='hidden mx-auto px-16 mb-10'>
        <DomainLogo authed />
        <div>
          <div className='sm:hidden'>
            <label htmlFor='tabs' className='sr-only'>
              Select a tab
            </label>
            <select
              id='tabs'
              name='tabs'
              className='block w-full rounded-md border-gray-300 focus:border-ev-blue focus:ring-ev-blue'
              defaultValue={tabs.find((tab) => tab.current)?.name}
            >
              {tabs.map((tab) => (
                <option key={tab.name}>{tab.name}</option>
              ))}
            </select>
          </div>
          <div className='hidden sm:block'>
            <div className=''>
              <nav className='-mb-px flex space-x-8' aria-label='Tabs'>
                {tabs.map((tab) => (
                  <button
                    key={tab.name}
                    onClick={() => navigate(tab.path)}
                    className={classNames(
                      tab.current
                        ? 'border-ev-blue text-ev-blue'
                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                      'group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium'
                    )}
                    aria-current={tab.current ? 'page' : undefined}
                  >
                    <tab.icon
                      className={classNames(
                        tab.current ? 'text-ev-blue' : 'text-gray-400 group-hover:text-gray-500',
                        '-ml-0.5 mr-2 h-5 w-5'
                      )}
                      aria-hidden='true'
                    />
                    <span>{tab.name}</span>
                  </button>
                ))}
              </nav>
            </div>
          </div>
        </div>
      </Flexor>
      <div className='sm:hidden flex justify-start bg-white shadow p-4'>
        <ElectionList/>
      </div>
    </div>
  )
}
