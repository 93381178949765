import { useEffect, useMemo, useState } from "react";
import {
  getPollworkerApplication,
  submitApplication,
  submitModernFormApplication,
  validateFormData
} from "../fetchers";
import { SectionSubHeading } from "shared/src/components/SectionHeading";
import PollworkerApplicationForm from "shared/src/components/PollworkerApplicationForm";
import {
  ArrowLeftIcon,
  CheckCircleIcon,
  XCircleIcon
} from "@heroicons/react/24/outline";
import { Link } from "wouter";
import Spinner from "shared/src/components/Spinner";
import { getWebsiteUserFromLocalStorage } from "../utils/localStorage";
import { Alert, AlertDescription } from 'shared/src/components/ui';
import { SurveyModel } from "survey-core";
import UserForm from "./UserForm";
import { zodResolver } from "@hookform/resolvers/zod";
import { FieldValues, useForm } from "react-hook-form";
import { generateFormSchema } from "./UserForm/formSchema";
import { z } from "zod";
import ErrorMessage from "./FormErrorMessage";
import { FormStatus } from "shared/src/enums/FormStatus";
import { Flexor } from "shared/src/components";
import { Button } from "shared/src/components/ui";
import getCaptchaToken from "../utils/captcha";

const applyFormSchema = generateFormSchema({
  agreement: z.boolean().refine(val => val, { message: "You must agree to the terms." }),
});

export const DEFAULT_ERROR_MESSAGE = 'Something went wrong. Please try again.';

function PollworkerStandardApplicationForm() {
  const [submittedApplication, setSubmittedApplication] = useState(false);
  const [submittingApplication, setSubmittingApplication] = useState(false);
  const [applicationSubmissionError, setApplicationSubmissionError] = useState<string>();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PollworkerProfile>({
    resolver: zodResolver(applyFormSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      streetAddress: '',
      streetAddress2: '',
      city: '',
      region: '',
      postalCode: '',
      email: '',
      homePhone: '',
      cellPhone: '',
      partyAffiliation: '',
      preferredContact: 'cellPhone',
      agreement: false,
    }
  });

  async function submit(data: FieldValues) {
    setSubmittingApplication(true);
    setApplicationSubmissionError(undefined);

    const token = await getCaptchaToken();

    const cellPhoneStripped = data.cellPhone?.replace(/\D/g, '');
    const homePhoneStripped = data.homePhone?.replace(/\D/g, '');

    submitApplication({
      firstName: data.firstName,
      lastName: data.lastName,
      address: data.streetAddress,
      address2: data.streetAddress2,
      city: data.city,
      state: data.region,
      zip: data.postalCode,
      email: data.email,
      homePhone: homePhoneStripped,
      cellPhone: cellPhoneStripped,
      partyAffiliation: data.partyAffiliation,
      useHomePhone: data.preferredContact === 'homePhone',
      useCellPhone: data.preferredContact === 'cellPhone',
      recaptchaVerificationToken: token,
    }).then((success) => {
      if (!success) {
        setApplicationSubmissionError(DEFAULT_ERROR_MESSAGE);
        setSubmittingApplication(false);
        return;
      }

      setSubmittedApplication(true);
    })
    .catch(() => {
      setSubmittingApplication(false);
      setApplicationSubmissionError(DEFAULT_ERROR_MESSAGE);
    });
  }

  const {PollworkerApplicationAgreementText, PollworkerApplicationThankYouMessage} = useMemo(() => getWebsiteUserFromLocalStorage() || {}, []);

  return (
    <div className='max-w-3xl mx-auto' role="form">
      {
        applicationSubmissionError ? (
          <div className="rounded-md bg-red-50 p-4 mb-10">
            <div className="flex">
              <div className="flex-shrink-0">
                <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800" role="heading">{applicationSubmissionError}</h3>
              </div>
            </div>
          </div>
        ) : null
      }

      {
        submittingApplication ? (
          <div className='fixed z-50 top-0 left-0 w-full h-full backdrop-blur-lg bg-white/20 flex items-center justify-center'>
            <div className='text-center space-y-10'>
              {
                submittedApplication ? (
                  <>
                    <CheckCircleIcon className='mx-auto h-20 w-20 text-green-500 bg-green-500/20 rounded-full'></CheckCircleIcon>
                    <div className='font-bold text-3xl'>Application submitted!</div>
                    <SectionSubHeading>{PollworkerApplicationThankYouMessage}</SectionSubHeading>
                    <Link className='block flex justify-center items-center space-x-2' to='/'>
                      <ArrowLeftIcon className='h-4 w-4' />
                      <div>Back to Home</div>
                    </Link>
                  </>
                ) : (
                  <div className='flex space-x-5'>
                    <Spinner large />
                    <div className='font-bold text-3xl'>Submitting application...</div>
                  </div>
                )
              }
            </div>
          </div>
        ) : null
      }

      <UserForm saving={submittingApplication} submitText='Apply' errors={errors} handleSubmit={handleSubmit(submit)} register={register}>
        <div className='sm:col-span-full relative'>
          <div className=" flex space-x-2 items-center">
            <input
              id="agreement"
              type="checkbox"
              className="h-4 w-4 border-gray-300 text-ev-red focus:ring-ev-red"
              {...register('agreement')}
            />
            <label htmlFor='agreement' className='text-sm font-semibold'>{PollworkerApplicationAgreementText}</label>
          </div>
          <ErrorMessage error={errors.agreement} />
        </div>

        <input
          type="hidden"
          data-testid="recaptcha-input"
          {...register('recaptcha')}
        />
      </UserForm>
    </div>
  )
}

export default function Apply() {
  const [formSchema, setFormSchema] = useState();
  const [hasCustomForm, setHasCustomForm] = useState(true);
  const [loadingApplication, setLoadingApplication] = useState(true);
  const [formId, setFormId] = useState<string>();
  const [submittedApplication, setSubmittedApplication] = useState(false);
  const [submittingApplication, setSubmittingApplication] = useState(false);
  const [applicationSubmissionError, setApplicationSubmissionError] = useState<string>();
  const [isDraftForm, setIsDraftForm] = useState<boolean>(false);

  const {PollworkerApplicationThankYouMessage, PollworkerApplicationAgreementText, CustomerId, PollworkerApplicationWelcomeMessage} = useMemo(() => getWebsiteUserFromLocalStorage() || {}, []);

  useEffect(() => {
    loadApplication();
  }, []);

  function loadApplication() {
    setLoadingApplication(true);

    const formId = new URLSearchParams(window.location.search).get('formId');

    getPollworkerApplication(formId).then((resp) => {
      const { CompiledFormJson: compiledFormJson, Id: id, Status: status } = resp;

      if (!compiledFormJson) return setHasCustomForm(false);

      setFormId(id);
      setIsDraftForm(status === FormStatus.Draft);
      setFormSchema(JSON.parse(compiledFormJson));
    })
    .finally(() => setLoadingApplication(false));
  }

  async function onComplete(sender: SurveyModel) {
    setSubmittingApplication(true);
    setApplicationSubmissionError(undefined);

    const token = await getCaptchaToken();

    return submitModernFormApplication({
      formDataJson: JSON.stringify(sender.data),
      recaptchaVerificationToken: token,
    }).then(({success, error}) => {
      setSubmittedApplication(success);
      setSubmittingApplication(success);
      setApplicationSubmissionError(success ? undefined : error || DEFAULT_ERROR_MESSAGE);

      return success;
    }).catch((err) => {
      console.error(err);
      setSubmittingApplication(false);
      setApplicationSubmissionError(DEFAULT_ERROR_MESSAGE);
    });
  }

  async function onValidate(formDataJson: { [key: string]: any; }, valid: (isValid: boolean) => void) {
    const token = await getCaptchaToken();

    setSubmittingApplication(true);

    validateFormData({
      recaptchaVerificationToken: token,
      formDataJson: JSON.stringify(formDataJson),
    })
    .then(({ success }) => {
      valid(success);
      setSubmittingApplication(success);
    })
    .catch(() => {
      valid(false);
      setSubmittingApplication(false);
    });
  }

  if (loadingApplication) return null;

  return (
    <div className='max-w-3xl mx-auto'>
      {
        applicationSubmissionError ? (
          <div className="rounded-md bg-red-50 p-4 mb-10">
            <div className="flex">
              <div className="flex-shrink-0">
                <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">{applicationSubmissionError}</h3>
              </div>
            </div>
          </div>
        ) : (
          <Alert variant='information'>
            <AlertDescription>{PollworkerApplicationWelcomeMessage}</AlertDescription>
          </Alert>
        )
      }

      {
        submittingApplication ? (
          <div className='fixed z-50 top-0 left-0 w-full h-full backdrop-blur-lg bg-white/20 flex items-center justify-center'>
            <div className='text-center space-y-10'>
              {
                submittedApplication ? (
                  <>
                    <CheckCircleIcon className='mx-auto h-20 w-20 text-green-500 bg-green-500/20 rounded-full'></CheckCircleIcon>
                    <div className='font-bold text-3xl'>Application submitted!</div>
                    <SectionSubHeading>{PollworkerApplicationThankYouMessage}</SectionSubHeading>
                    <Link className='flex justify-center items-center space-x-2' to='/'>
                      <ArrowLeftIcon className='h-4 w-4' />
                      <div>Back to Home</div>
                    </Link>
                  </>
                ) : (
                  <div className='flex space-x-5'>
                    <Spinner large />
                    <div className='font-bold text-3xl'>Submitting application...</div>
                  </div>
                )
              }
            </div>
          </div>
        ) : null
      }

      {
        hasCustomForm && formSchema ? (
          <>
            <div className='mt-5'>
              <PollworkerApplicationForm
                surveyFormSchema={formSchema}
                onValidate={onValidate}
                onComplete={onComplete}
                allowSubmit={!isDraftForm}
                customerId={CustomerId}
                agreementText={PollworkerApplicationAgreementText}
              />
              {formId ? <div className='text-xs text-gray-200 -mt-5'>Form version: {formId.substring(0, 7)}</div> : null }
            </div>
            {
              isDraftForm ? (
                <Flexor className='border-t pt-5 mt-5 space-x-2'>
                  <div className="grow animate-pulse">
                    <Alert variant="error">
                      <AlertDescription>This this form is a DRAFT! It is not submittable.</AlertDescription>
                    </Alert>
                  </div>
                  <Button onClick={() => loadApplication()}>
                    <Spinner show={loadingApplication} />
                    <div>Refresh</div>
                  </Button>
                </Flexor>
              ) : null
            }
          </>
        ) : (
          <PollworkerStandardApplicationForm />
        )
      }
    </div>
  );
}
