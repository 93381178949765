import UserForm from "./UserForm";
import { generateFormSchema } from "./UserForm/formSchema";
import { FieldValues, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { getUserProfile, updateUserProfile } from "../fetchers";
import { useAppSelector } from "../hooks";
import { SectionSubHeading } from "shared/src/components/SectionHeading";
import { ContactMethod } from "../enums/contactMethod";
import { useState } from "react";
import { toast } from "react-toastify";
import { ScreenReaderHidden } from "shared/src/components/Accessibility";

const profileSchema = generateFormSchema();

export default function Profile() {
  const currentUser = useAppSelector((state) => state.currentPollworkerUser);
  const [savingProfile, setSavingProfile] = useState<boolean>(false);
  const [loadedUserProfile, setLoadedUserProfile] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Partial<PollworkerProfile>>({
    resolver: zodResolver(profileSchema),
    defaultValues: async () => {
      const userProfileData: PollworkerUserProfileFromAPI = await getUserProfile(currentUser.EVUserId)
        .finally(() => {
          setLoadedUserProfile(true);
        });

      return {
        firstName: userProfileData?.FirstName || '',
        lastName: userProfileData?.LastName || '',
        streetAddress: userProfileData?.Address1 || '',
        streetAddress2: userProfileData?.Address2 || '',
        city: userProfileData?.City || '',
        region: userProfileData?.State || '',
        postalCode: userProfileData?.Zip || '',
        email: userProfileData?.Email || '',
        homePhone: userProfileData?.HomePhone || '',
        cellPhone: userProfileData?.CellPhone || '',
        partyAffiliation: userProfileData?.PoliticalParty || '',
        preferredContact: userProfileData.ContactMethod === 1 ? 'homePhone' : 'cellPhone',
      };
    },
  });

  function submit(data: FieldValues) {
    setSavingProfile(true);

    const cellPhoneStripped = data.cellPhone?.replace(/\D/g, '');
    const homePhoneStripped = data.homePhone?.replace(/\D/g, '');

    updateUserProfile(currentUser.EVUserId, {
      FirstName: data.firstName,
      LastName: data.lastName,
      Address1: data.streetAddress,
      Address2: data.streetAddress2,
      City: data.city,
      State: data.region,
      Zip: data.postalCode,
      CellPhone: cellPhoneStripped,
      HomePhone: homePhoneStripped,
      Email: data.email,
      ContactMethod: data.preferredContact === 'cellPhone' ? ContactMethod.CellPhone : ContactMethod.HomePhone,
      PoliticalParty: data.partyAffiliation,
    } as PollworkerUserProfileFromAPI)
    .then((success) => {
      if (success) return toast.success('Profile saved!');
      toast.error('Could not save profile, try again.');
    })
    .finally(() => setSavingProfile(false));
  }

  const loading = !loadedUserProfile || savingProfile;

  return (
    <>
      <SectionSubHeading
        className="pt-2 inline-flex font-semibold"
        role="heading"
      >
        <span aria-labelledby='user-profile' tabIndex={-1}>
          <ScreenReaderHidden>
            <span id='user-profile'>
              {currentUser?.FirstName} {currentUser?.LastName}'s Profile
            </span>
          </ScreenReaderHidden>
        </span>
      </SectionSubHeading>
      <UserForm register={register} saving={loading} handleSubmit={handleSubmit(submit)} errors={errors} submitText='Save' />
    </>
  )
}
