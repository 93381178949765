import {Listbox, Transition} from "@headlessui/react";
import {setElectionId} from "../state/actions";
import {classNames} from "shared/src/utils/classNames";
import {BookmarkSquareIcon, CheckIcon, ChevronDownIcon} from "@heroicons/react/24/outline";
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import {useAppDispatch, useAppSelector} from "../hooks";
import { getElections, getWorkSchedule } from "pollworker/src/fetchers";
import { WorkSchedule } from 'pollworker/src/types';
import { setCurrentUserWorkSchedules } from 'pollworker/src/state/schedule';

export default function ElectionList() {
  const [elections, setElections] = useState<{ Id: string, ElectionName: string }[]>([]);
  const selectedElectionId = useAppSelector((state) => state.election.electionId);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!dispatch) return;

    getElections().then((elections) => {
      setElections(elections);
      if (elections.length) dispatch(setElectionId(elections[0].Id));
    });
  }, [dispatch]);

  useEffect(() => {
    if (!selectedElectionId) return;

    loadWorkSchedules();
  }, [selectedElectionId]);

  const handleElectionChange = useCallback((newElectionId: string) => {
    dispatch(setElectionId(newElectionId));

  }, [dispatch]);

  function loadWorkSchedules() {
    getWorkSchedule(selectedElectionId).then((response: WorkSchedule[]) => {
      dispatch(setCurrentUserWorkSchedules(response));
    });
  }

  const selectedElection = useMemo(() => {
    if (!elections.length && !selectedElectionId) return;
    if (elections.length && !selectedElectionId) return elections[0];

    return elections.find((el) => el.Id === selectedElectionId);
  }, [elections, selectedElectionId]);

  return (
    <Listbox defaultValue={selectedElection?.Id} onChange={handleElectionChange}>
      {({open}) => (
        <>
          <div className="relative text-sm">
            <Listbox.Button data-testid='elections-list'
              className={classNames(open ? 'ring-ev-red' : 'ring-gray-500', 'relative max-w-52 flex items-center space-x-1 font-semibold text-gray-900 focus:outline-none ring-2 py-1 px-2 sm:leading-6 rounded')}
              aria-label='select an election'
            >
              <BookmarkSquareIcon className='h-5 w-5' aria-hidden="true"/>
              <span className="block truncate">{selectedElection?.ElectionName}</span>
              <span className="pointer-events-none inset-y-0 right-0 flex items-center">
                <ChevronDownIcon className="h-4 w-4 text-gray-700" aria-hidden="true"/>
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className="absolute z-10 mt-1 max-h-60 max-w-[14em] sm:w-[20em] sm:right-0 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {
                  elections.map((election) => (
                    <Listbox.Option
                      key={election.Id}
                      className={({active}) =>
                        classNames(
                          active ? 'bg-ev-red text-white' : 'text-gray-900',
                            'relative cursor-default select-none py-2 pl-3 pr-9'
                          )}
                      value={election.Id}
                      >
                        {({selected, active}) => (
                          <>
                            <span
                              className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}
                            >
                              {election.ElectionName}
                            </span>
                            {selected ? (
                              <span
                                className={classNames(
                                  active ? 'text-white' : 'text-ev-red',
                                  'absolute inset-y-0 right-0 flex items-center pr-4'
                                )}
                              >
                                <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    )
                  )}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}
