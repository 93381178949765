import React from "react";
import { useSelector } from "react-redux";
import {phoneNumberFormatter} from "shared/src/utils/formatters";
import { PollworkerPublicState } from "../state/reducers";
import dayjs from "dayjs";
import {ScreenReaderHidden} from "shared/src/components/Accessibility";

const thisYear = dayjs().format('YYYY');

export default function Footer() {
  const currentUser = useSelector((state: PollworkerPublicState) => state.currentPollworkerUser);

  return (
    <footer role="contentinfo">
      <div
        className='mt-5 w-full mx-auto text-left lg:text-center text-sm font-semibold text-gray-600 pb-20'>
        <div className='px-4 lg:px-16 lg:space-y-0 border-t border-gray-100 pt-5'>
          <div className='mx-auto max-w-7xl space-y-5 lg:space-y-0 lg:flex justify-between items-center'>
            {
              currentUser.Customer ? (
                <div className='text-left'>
                  <span aria-labelledby='customer-name customer-address customer-phone' tabIndex={-1}>
                    <ScreenReaderHidden>
                      <div id='customer-name'>{currentUser.Customer.Name}</div>
                      <div id='customer-address'>{currentUser.Customer.City}, {currentUser.Customer.State} {currentUser.Customer.Zip}</div>
                      <div id='customer-phone'>{phoneNumberFormatter({value: currentUser.Customer.Phone})}</div>
                    </ScreenReaderHidden>
                  </span>
                </div>
              ) : null
            }
            <span aria-labelledby='copyright' tabIndex={-1}>
              <ScreenReaderHidden>
                <div id='copyright'>© 2014 - {thisYear} by EasyVote Solutions, Inc. All rights reserved.</div>
              </ScreenReaderHidden>
            </span>
          </div>
        </div>
      </div>
    </footer>
  )
}
