import ErrorMessage from "../FormErrorMessage";
import { STATES } from "shared/src/utils/data/usStates";
import { capitalize } from "lodash";
import { ReactNode, useEffect, useState } from "react";
import { UseFormRegister } from "react-hook-form/dist/types/form";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import { getPoliticalParties } from "../../fetchers";
import { Spinner } from "shared/src/components";
import { Button } from "shared/src/components/ui/Button";

type UserFormProps = {
  errors: FieldErrors<any>,
  register: UseFormRegister<any>,
  handleSubmit: () => void,
  children?: ReactNode[],
  submitText: string,
  saving: boolean,
}

type PoliticalParty = {
  PartyCode: string,
  PartyName: string,
}

export default function UserForm({handleSubmit, saving, register, errors, children, submitText}: UserFormProps) {
  const [politicalParties, setPoliticalParties] = useState<PoliticalParty[]>([]);

  useEffect(() => {
    getPoliticalParties().then((parties) => {
      if (!parties) return;

      setPoliticalParties(parties);
    });
  }, []);

  return (
    <form onSubmit={handleSubmit} className='space-y-10 mt-10 divide-y divide-gray-900/10'>
      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-900" role="heading">Personal Information</h2>
        <p className="mt-1 text-sm leading-6 text-gray-600">Use a permanent address where you can receive mail.</p>

        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <label htmlFor="firstName" className="block text-sm font-medium leading-6 text-gray-900">
              First name
            </label>
            <div className="mt-2">
              <input
                type="text"
                id="firstName"
                placeholder='First name'
                autoComplete="given-name"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ev-red sm:text-sm sm:leading-6"
                {...register('firstName')}
              />
              <ErrorMessage error={errors.firstName} />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
              Last name
            </label>
            <div className="mt-2">
              <input
                type="text"
                id="last-name"
                placeholder='Last name'
                autoComplete="family-name"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ev-red sm:text-sm sm:leading-6"
                {...register('lastName')}
              />
              <ErrorMessage error={errors.lastName} />
            </div>
          </div>

          <div className="col-span-full">
            <label htmlFor="streetAddress" className="block text-sm font-medium leading-6 text-gray-900">
              Street address
            </label>
            <div className="mt-2">
              <input
                type="text"
                id="streetAddress"
                placeholder='Street address'
                autoComplete="street-address"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ev-red sm:text-sm sm:leading-6"
                {...register('streetAddress')}
              />
              <ErrorMessage error={errors.streetAddress} />
            </div>
          </div>

          <div className="col-span-full">
            <label htmlFor="streetAddress2" className="block text-sm font-medium leading-6 text-gray-900">
              Street address 2
            </label>
            <div className="mt-2">
              <input
                type="text"
                id="streetAddress2"
                placeholder='Secondary street address (ex: APT. 22)'
                autoComplete="streetAddress2"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ev-red sm:text-sm sm:leading-6"
                {...register('streetAddress2')}
              />
              <ErrorMessage error={errors.streetAddress2} />
            </div>
          </div>

          <div className="sm:col-span-2 sm:col-start-1">
            <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900">
              City
            </label>
            <div className="mt-2">
              <input
                type="text"
                id="city"
                placeholder='City'
                autoComplete="address-level2"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ev-red sm:text-sm sm:leading-6"
                {...register('city')}
              />
              <ErrorMessage error={errors.city} />
            </div>
          </div>

          <div className="sm:col-span-2">
            <label htmlFor="region" className="block text-sm font-medium leading-6 text-gray-900">
              State / Province
            </label>
            <div className="mt-2">
              <select
                id="region"
                autoComplete="address-level1"
                // @ts-ignore
                placeholder='State'
                className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-ev-red sm:text-sm sm:leading-6"
                defaultValue=''
                {...register('region')}
              >
                <option value='' disabled>Select a state...</option>
                {
                  STATES.map(({abbr, state}) => {
                    return <option key={abbr} value={abbr} className='capitalize'>{state.split(' ').map(capitalize).join(' ')} ({abbr})</option>
                  })
                }
              </select>
              <ErrorMessage error={errors.region} />
            </div>
          </div>

          <div className="sm:col-span-2">
            <label htmlFor="postal-code" className="block text-sm font-medium leading-6 text-gray-900">
              ZIP / Postal code
            </label>
            <div className="mt-2">
              <input
                type="text"
                id="postalCode"
                autoComplete="postal-code"
                placeholder='Zip code'
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ev-red sm:text-sm sm:leading-6"
                {...register('postalCode')}
              />
              <ErrorMessage error={errors.postalCode} />
            </div>
          </div>
        </div>
      </div>

      <div className="border-b border-gray-900/10 pt-8">
        <div>
          <label htmlFor="political-party" className="block text-sm font-medium leading-6 text-gray-900">
            Political Party
          </label>
          <div className="mt-2 flex space-x-2">
            <div className='w-1/2'>
              <select
                id="political-party"
                className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-ev-red sm:text-sm sm:leading-6"
                {...register('partyAffiliation')}
              >
                <option value='' disabled>{politicalParties.length ? 'Select a party...' : 'No parties available'}</option>
                {
                  politicalParties.map(({PartyCode: value, PartyName: label}) => {
                    return <option key={value} value={value} className='capitalize'>{label}</option>
                  })
                }
              </select>
              <ErrorMessage error={errors.partyAffiliation} />
            </div>
            <p className='w-1/3 text-sm p-1'>This information is optional. It is sometimes used to ensure an equal distribution of poll workers among polling places.</p>
          </div>

        </div>
      </div>

      <div className="border-b border-gray-900/10 pt-8">
        <h2 className="text-base font-semibold leading-7 text-gray-900" role="heading">Contact Information</h2>
        <p className="mt-1 text-sm leading-6 text-gray-600">Enter your preferred contact information.</p>

        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-full">
            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
              Email address
            </label>
            <div className="mt-2">
              <input
                id="email"
                type="email"
                autoComplete="email"
                placeholder='Email address'
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ev-red sm:text-sm sm:leading-6"
                {...register('email')}
              />
              <ErrorMessage error={errors.email} />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label htmlFor="cellPhone" className="block text-sm font-medium leading-6 text-gray-900">
              Cell Phone
            </label>
            <div className="mt-2 relative">
              <input
                type="text"
                id="cellPhone"
                placeholder='Cell phone number'
                autoComplete="tel-area-code"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ev-red sm:text-sm sm:leading-6"
                {...register('cellPhone')}
              />
              <ErrorMessage error={errors.cellPhone} />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label htmlFor="homePhone" className="block text-sm font-medium leading-6 text-gray-900">
              Home Phone
            </label>
            <div className="mt-2 relative">
              <input
                type="text"
                id="homePhone"
                placeholder='Home phone number'
                autoComplete="tel-area-code"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ev-red sm:text-sm sm:leading-6"
                {...register('homePhone')}
              />
              <ErrorMessage error={errors.homePhone} />
            </div>
          </div>

          <div className="sm:col-span-3">
            <div className="flex items-center gap-x-3">
              <input
                id="prefer-cell-phone"
                type="radio"
                value='cellPhone'
                className="h-4 w-4 border-gray-300 text-ev-red focus:ring-ev-red"
                {...register('preferredContact')}
              />
              <label htmlFor="prefer-cell-phone" className="block text-sm font-medium leading-6 text-gray-900">
                Prefer cell phone
              </label>
            </div>
            <ErrorMessage error={errors.preferredContact} />
          </div>
          <div className="sm:col-span-3">
            <div className="flex items-center gap-x-3">
              <input
                id="prefer-home-phone"
                type="radio"
                className="h-4 w-4 border-gray-300 text-ev-red focus:ring-ev-red"
                value='homePhone'
                {...register('preferredContact')}
              />
              <label htmlFor="prefer-home-phone" className="block text-sm font-medium leading-6 text-gray-900">
                Prefer home phone
              </label>
            </div>
          </div>

          {children}

          <div className='sm:flex justify-end col-span-full'>
            <div className='sm:mt-0 mt-5 flex flex-col justify-center'>
              <Button type="submit" disabled={saving}>
                <Spinner show={saving} light />
                <span>{submitText}</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}
