import {useAppSelector} from "../hooks";
import {Flexor} from "shared/src/components";
import {
  AcademicCapIcon,
  ArrowLeftEndOnRectangleIcon,
  BuildingOffice2Icon,
  CalendarIcon, PhoneIcon, PrinterIcon
} from "@heroicons/react/24/outline";
import Tippy from "@tippyjs/react";
import TippyContent from "shared/src/components/TippyContent";
import {renderDataAttribute} from "./WorkSchedules";
import {phoneNumberFormatter} from "shared/src/utils/formatters";
import React from "react";
import {getClassDocumentBySessionId} from "../fetchers";
import {openPdfToPrint} from "shared/src/utils/downloadDocument";
import {ScreenReaderOnly} from "shared/src/components/Accessibility";
import { Button } from "shared/src/components/ui/Button";

export function DetailsPanel({scheduledClassId, classDetails, setShowScheduleView, setShowDropClassView}: any) {
  const currentUser = useAppSelector(state => state.currentPollworkerUser);

  if (!classDetails) {
    return (
      <div className='w-full h-32 flex flex-col items-center justify-center'>
        <div>Getting class schedule...</div>
      </div>
    )
  }

  return (
    <div>
      <div className='grid grid-cols-2 gap-4 text-md my-4'>
        <Flexor justify='start' className='space-x-2'>
          <AcademicCapIcon className='h-5 w-5'/>
          <ScreenReaderOnly>Instructor</ScreenReaderOnly>
          <Tippy content={<TippyContent>Instructor</TippyContent>}>
            {renderDataAttribute(classDetails?.Instructor)}
          </Tippy>
        </Flexor>
        <Flexor justify='start' className='space-x-2'>
          <ArrowLeftEndOnRectangleIcon className='h-5 w-5' />
          <ScreenReaderOnly>Room</ScreenReaderOnly>
          <Tippy content={<TippyContent>Room</TippyContent>}>
            { renderDataAttribute(classDetails?.Room) }
          </Tippy>
        </Flexor>
        <Flexor className='col-span-full'>
          <Flexor justify='start' className='space-x-2'>
            <CalendarIcon className='h-5 w-5' />
            <ScreenReaderOnly>Date and Time</ScreenReaderOnly>
            <span className='font-semibold'>{renderDataAttribute(classDetails?.StartDate)}</span>
            <span>from</span>
            <span className='font-semibold'>{renderDataAttribute(classDetails?.StartTime)}</span>
            <span>to</span>
            <span className='font-semibold'>{renderDataAttribute(classDetails?.EndTime)}</span>
          </Flexor>
        </Flexor>
        <div className='col-span-full space-x-2'>
          <BuildingOffice2Icon className='h-5 w-5 inline' />
          <ScreenReaderOnly>Address</ScreenReaderOnly>
          <span>
            {renderDataAttribute(classDetails?.Address)}
            <span> at </span>
            {renderDataAttribute(classDetails?.Location)}
          </span>
        </div>
        <div className='space-x-2'>
          <ScreenReaderOnly>Contact Phone</ScreenReaderOnly>
          <PhoneIcon className='h-5 w-5 inline' />
          {renderDataAttribute(phoneNumberFormatter({ value: classDetails?.ContactPhone}))}
        </div>
      </div>
      <div className=' hidden text-sm text-gray-700'>
        <div className='my-2 mr-5'>
          <p><span className='font-medium'>Start Date:</span> {classDetails?.StartDate || 'Not specified'}</p>
          <p><span className='font-medium'>Start Time:</span> {classDetails?.StartTime || 'Not specified'}</p>
          <p><span className='font-medium'>End Time:</span> {classDetails?.EndTime || 'Not specified'}</p>
          <p><span className='font-medium'>Address:</span> {classDetails?.Address || 'Not specified'}</p>
        </div>
        <div className='my-5 mr-5 w-auto'>
          <p><span className='font-medium'>Location:</span> {classDetails?.Location || 'Not specified'}</p>
          <p><span className='font-medium'>Contact Phone:</span> {classDetails?.ContactPhone || 'Not specified'}</p>
          <p><span className='font-medium'>Room:</span> {classDetails?.Room || 'Not specified'}</p>
        </div>

      </div>

      <div className='flex justify-between items-center'>
        <Button variant='tertiary' onClick={() => setShowScheduleView(false)}>
          Cancel
        </Button>

        <div className='flex space-x-4'>
          <Button variant='secondary' className='flex items-center' onClick={() => printScheduledClassDetails(scheduledClassId, currentUser)}>
            <PrinterIcon className='h-4 w-4' />
            <span>Print</span>
          </Button>
          <Button
            onClick={() => {
              setShowScheduleView(false);
              setShowDropClassView(true);
            }}
          >
            Drop Class
          </Button>
        </div>
      </div>
    </div>
  )
}

function printScheduledClassDetails(scheduledClassId: string, currentUser: any) {
  getClassDocumentBySessionId(scheduledClassId, currentUser.EVUserId).then(openPdfToPrint);
}

