import React, {useState, useEffect, useCallback, Fragment, useMemo} from 'react';
import PollworkerScheduleCardGrid from './PollworkerScheduleCardGrid';
import { PollworkerTimeclockEntry, WorkSchedule } from '../types';
import { useAppDispatch, useAppSelector, useIsFeatureFlagEnabled } from '../hooks';
import {
  selectCurrentUserIsManagerInElection,
  selectCurrentUserWorkSchedules
} from 'pollworker/src/state/schedule';
import {
  clockInSchedule,
  clockOutSchedule,
  getSchedulesForCoworkers
} from 'pollworker/src/fetchers';
import { selectCoworkerSchedules, setCoworkerSchedules, updateCoworkerSchedule } from '../state/timeclock';
import dayjs from "dayjs";
import { Listbox, Transition } from '@headlessui/react';
import { classNames } from 'shared/src/utils';
import { MapPinIcon, CheckIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { Spinner } from 'shared/src/components';

export default PollworkerTimeclock;

function PollworkerTimeclock() {
  const dispatch = useAppDispatch();
  const timeclockEnabled = useIsFeatureFlagEnabled('pollworker-timeclock');
  const currentUserIsManagerForElection = useAppSelector(selectCurrentUserIsManagerInElection);
  const workSchedules = useAppSelector(selectCurrentUserWorkSchedules);
  const coworkerSchedules = useAppSelector(selectCoworkerSchedules);
  const [selectedSchedule, setSelectedSchedule] = useState<WorkSchedule | null>(null);
  const [loadingCoworkerSchedules, setLoadingCoworkerSchedules] = useState<boolean>(false);

  useEffect(() => {
    if (!!selectedSchedule) {
      loadCoworkerSchedules(selectedSchedule.Id)
    }
  }, [selectedSchedule]);

  const selectedWorkDate = useMemo(() => {
    if (selectedSchedule) {
      return formatWorkDate(selectedSchedule.WorkDateExact);
    }
    return '';
  }, [selectedSchedule]);

  useEffect(() => {
    if (workSchedules.length > 0) {
      const todayString = dayjs().format('YYYY-MM-DD');
      let closestFutureScheduleIndex = workSchedules.findIndex(schedule => schedule.HasReports && schedule.WorkDateExact >= todayString);
      if (closestFutureScheduleIndex === -1) {
        closestFutureScheduleIndex = workSchedules.length - 1;
      }
      setSelectedSchedule(workSchedules[closestFutureScheduleIndex]);
    }
  }, [workSchedules]);

  const handleClockIn = useCallback((schedule: WorkSchedule) => {
    return clockInSchedule(schedule.Id).then((newEntry: PollworkerTimeclockEntry) => {
      updateScheduleWithEntry(schedule, newEntry, dispatch);
    });
  }, [dispatch]);

  const handleClockOut = useCallback((schedule: WorkSchedule) => {
    return clockOutSchedule(schedule.Id).then((newEntry: PollworkerTimeclockEntry) => {
      updateScheduleWithEntry(schedule, newEntry, dispatch);
    });
  }, [dispatch]);

  function updateScheduleWithEntry(schedule: WorkSchedule, newEntry: PollworkerTimeclockEntry, _dispatch: typeof dispatch) {
    const newSchedule: WorkSchedule = {
      ...schedule
    }
    const indexToUpdate = newSchedule.TimeclockEntries.findIndex(entry => entry.Id === newEntry.Id);

    if (indexToUpdate === -1) {
      newSchedule.TimeclockEntries = [newEntry];
    } else {
      newSchedule.TimeclockEntries = [
        ...newSchedule.TimeclockEntries.slice(0, indexToUpdate),
        newEntry,
        ...newSchedule.TimeclockEntries.slice(indexToUpdate+1)
      ];
    }
    _dispatch(updateCoworkerSchedule(newSchedule));
  }

  function loadCoworkerSchedules(scheduleId: string) {
    setLoadingCoworkerSchedules(true);

    getSchedulesForCoworkers(scheduleId).then((schedules: WorkSchedule[]) => {
      dispatch(setCoworkerSchedules(schedules));
    }).finally(() => {
      setLoadingCoworkerSchedules(false);
    });
  }

  function formatWorkDate(workDate: string) {
    return dayjs(workDate).format('MM/DD/YYYY');
  }

  return (
    <div className="mt-4">
      {!timeclockEnabled && (
        <div className="p-40 text-center text-xl">This feature is not enabled for your account. Contact your account manager if you believe this is a mistake.</div>
      )}
      {timeclockEnabled && !currentUserIsManagerForElection && (
        <div className="p-40 text-center text-xl">You do not have any Manager-level work assignments for this election.</div>
      )}
      {timeclockEnabled && currentUserIsManagerForElection && (
        <>
          <Listbox value={selectedSchedule} onChange={(value: WorkSchedule) => setSelectedSchedule(value)}>
            {({open}) => (
              <>
                <div className="relative text-sm">
                  <Listbox.Button data-testid="work-schedule-list"
                                  className="relative max-w-52 flex items-center space-x-1 font-semibold text-gray-900 focus:outline-none py-1 px-2 sm:leading-6"
                                  aria-label='select an election'
                  >
                    <div className="flex flex-col text-lg">
                      <div className="flex items-center gap-1">
                        <MapPinIcon className='h-5 w-5' aria-hidden="true"/>
                        <span className="truncate">{selectedSchedule?.VotingLocationName}</span>
                        <span className="pointer-events-none inset-y-0 right-0 items-center">
                          <ChevronDownIcon className="h-4 w-4 text-gray-700" aria-hidden="true"/>
                        </span>
                      </div>
                      <div className="pl-6 self-start truncate">
                        {selectedSchedule && selectedWorkDate}
                      </div>
                    </div>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options
                      className="absolute z-10 mt-1 max-h-60 max-w-[14em] sm:w-[20em] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {
                        workSchedules.map((schedule) => (
                            <Listbox.Option
                              key={schedule.Id}
                              className={({active}) =>
                                classNames(
                                  active ? 'bg-ev-red text-white' : 'text-gray-900',
                                  'relative cursor-default select-none py-2 pl-3 pr-9'
                                )}
                              disabled={!schedule.HasReports}
                              value={schedule}
                            >
                              {({selected, active}) => (
                                <div className={classNames(
                                  selected ? 'font-bold' : '',
                                  schedule.HasReports ? 'font-semibold cursor-pointer' : 'italic neutral-300 cursor-not-allowed',
                                  'block truncate',
                                )}>
                                  <div>
                                    <span>
                                      {schedule.VotingLocationName}
                                    </span>
                                    {selected ? (
                                      <span
                                        className={classNames(
                                          active ? 'text-white' : 'text-ev-red',
                                          'absolute inset-y-0 right-0 flex items-center pr-4'
                                        )}
                                      >
                                        <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                                      </span>
                                    ) : null}
                                  </div>
                                  <div>
                                    {formatWorkDate(schedule.WorkDateExact)}
                                  </div>
                                </div>
                              )}
                            </Listbox.Option>
                          )
                        )}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
          {selectedSchedule && selectedSchedule.HasReports && (
            <PollworkerScheduleCardGrid
              className="m-4 schedule-grid"
              pollworkerSchedules={coworkerSchedules}
              onClockIn={handleClockIn}
              onClockOut={handleClockOut}
            />
          )}
          {selectedSchedule && !selectedSchedule.HasReports && (
            <div className="p-40 text-center text-xl">Workers with the role <b>{selectedSchedule.RoleName}</b> cannot manage timeclock entries.</div>
          )}
          {workSchedules && workSchedules.length && !selectedSchedule && (
            <div className="p-40 text-center text-xl">You do not have any Manager-level work assignments for this election.</div>
          )}
          <Spinner large show={loadingCoworkerSchedules} />
        </>
      )}
    </div>
  );
};

