import tw from "tailwind-styled-components";

export const LinkButton = tw.button`
inline 
text-ev-blue 
font-medium 
cursor-pointer 
underline
hover:no-underline
`;

export const LinkAnchor = tw.a`
inline
text-ev-blue
font-medium
cursor-pointer
underline
hover:no-underline
`;