import React, { Fragment, useState } from "react";
import { getStatusLabel, renderDataAttribute } from "./WorkSchedules";
import DateFormatter from 'shared/src/components/DateFormatter';
import { WorkSchedule } from "../types/WorkSchedule";
import {ScreenReaderOnly} from "shared/src/components/Accessibility";
import { Button } from "shared/src/components/ui/Button";
import ScheduledCoworkersList from "./ScheduledCoworkersList";
import { Flexor } from "shared/src/components";
import { Dialog, DialogContent, DialogHeader } from "shared/src/components/ui/Dialog";
import { DialogTitle, DialogTrigger } from "@radix-ui/react-dialog";
import WorkScheduleResponseForm from "./WorkScheduleResponseForm";

type WorkScheduleTableViewProps = {
  workSchedules: WorkSchedule[],
}

export default function WorkScheduleTableView({workSchedules}: WorkScheduleTableViewProps) {
  const [showWorkConfirmation, setShowWorkConfirmation] = useState<WorkSchedule>();

  function renderOptions(workSchedule: WorkSchedule) {
    return (
      <>
        <Dialog size='lg'>
          <DialogTrigger asChild>
            <Button variant='secondary' className='text-sm w-full'>Coworkers</Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>{`Coworkers for ${workSchedule.DisplayWorkDate}`}</DialogTitle>
            </DialogHeader>
            <div className="min-h-56 relative">
              <ScheduledCoworkersList workScheduleId={workSchedule.Id} />
            </div>
          </DialogContent>
        </Dialog>
        <Button variant='primary' className='w-full' onClick={() => setShowWorkConfirmation(workSchedule)}>{workSchedule.ConfirmationStatus ? 'Change' : 'Respond'}</Button>
      </>
    );
  }

  return (
    <table className="w-full divide-y divide-gray-300">
      <thead>
      <tr>
        <th
          scope="col"
          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:hidden table-cell"
        >
          Summary
        </th>
        <th
          scope="col"
          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
        >
          Date
        </th>
        <th
          scope="col"
          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
        >
          Shift
        </th>
        <th
          scope="col"
          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell md:table-cell"
        >
          Location
        </th>
        <th
          scope="col"
          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
        >
          Role
        </th>
        <th
          scope="col"
          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
        >
          Address
        </th>
        <th
          scope="col"
          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
        >
          Your Response
        </th>
        <th>
          <ScreenReaderOnly>Options</ScreenReaderOnly>
        </th>
      </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 bg-white">
      {
        workSchedules.map((workSchedule) => (
          <Fragment key={workSchedule.Id}>
            <Dialog open={showWorkConfirmation?.Id === workSchedule.Id} onOpenChange={() => setShowWorkConfirmation(undefined)}>
              <DialogContent>
                <WorkScheduleResponseForm
                  closeModal={() => setShowWorkConfirmation(undefined)}
                  workScheduleId={workSchedule.Id}
                />
              </DialogContent>
            </Dialog>
            <tr data-testid={`schedule-${workSchedule.Id}`} className='hover:bg-gray-100 transition-colors'>
              <td className="px-3 py-4 text-sm text-gray-800 lg:table-cell">
                <span className='hidden lg:inline'>{workSchedule.DisplayWorkDate}</span>
                <dl className="font-normal lg:hidden">
                  <dd className="mt-1 truncate text-gray-700">
                    <DateFormatter dateString={workSchedule.DisplayWorkDate} withTime={false}/> from {renderDataAttribute(workSchedule.StartTime)} until {renderDataAttribute(workSchedule.EndTime)}
                  </dd>
                  <dd className="mt-1 truncate text-gray-700" id='role'>
                    <ScreenReaderOnly>, with the</ScreenReaderOnly> {renderDataAttribute(workSchedule.RoleName)}
                    <ScreenReaderOnly>role</ScreenReaderOnly>
                  </dd>
                  <dd className="mt-1 truncate text-gray-700">
                    <ScreenReaderOnly>, at</ScreenReaderOnly>
                    {renderDataAttribute(workSchedule.VotingLocationName)}
                    <ScreenReaderOnly>, at</ScreenReaderOnly>
                  </dd>
                  <dd className="mt-1 truncate text-gray-700">{renderDataAttribute(workSchedule.FullAddress)}</dd>
                </dl>
                <Flexor className='sm:hidden space-x-2 mt-2'>
                  {renderOptions(workSchedule)}
                </Flexor>
              </td>
              <td className="hidden px-3 py-4 text-sm text-gray-800 lg:table-cell text-nowrap">
                {renderDataAttribute(workSchedule.StartTime)} → {renderDataAttribute(workSchedule.EndTime)}
              </td>
              <td className="hidden px-3 py-4 text-sm text-gray-800 lg:table-cell md:table-cell">
                {renderDataAttribute(workSchedule.VotingLocationName)}
              </td>
              <td className="hidden px-3 py-4 text-sm text-gray-800 lg:table-cell">
                {renderDataAttribute(workSchedule.RoleName)}
              </td>
              <td className="hidden px-3 py-4 text-sm text-gray-800 lg:table-cell">
                {renderDataAttribute(workSchedule.FullAddress)}
              </td>
              <td className="hidden px-3 py-4 text-sm text-gray-800 lg:table-cell text-nowrap">
                <Flexor justify='start' className='space-x-2'>
                  {getStatusLabel(workSchedule)}
                </Flexor>
              </td>
              <td className='hidden sm:table-cell'>
                <Flexor className='space-x-2'>
                  {renderOptions(workSchedule)}
                </Flexor>
              </td>
            </tr>
          </Fragment>
        ))
      }
      </tbody>
    </table>
  )
}
