import { useParams } from "wouter";
import React, { useState } from "react";
import {CreateLoginCredentials} from "./RegistrationCreateLogin";
import {VerifyUserIdentity} from "./RegistrationVerifyUserID";

export type UserCredentials = {
  FirstName: string,
  LastName: string,
  VRNumber: string,
}

export type LoginCredentials = {
  UserName: string,
  Password: string,
  CredentialsInfo: UserCredentials,
}

export type FormProps = {
  userNameIsUnique?: boolean,
  passwordIsValid: boolean,
  passwordMatches: boolean,
  checkingUserName?: boolean,
}

export default function Registration() {
  const {evUserId} = useParams() as { evUserId: string };
  const [userCredentials, setUserCredentials] = useState<UserCredentials>(
    { FirstName: '', LastName: '', VRNumber: '' });
  const [proceedToNextStep, setProceedToNextStep] = useState(false);

  return (
    <div>
      {proceedToNextStep ?
        <CreateLoginCredentials evUserId={evUserId} userCredentials={userCredentials}/> :
        <VerifyUserIdentity evUserId={evUserId} setUserCredentials={setUserCredentials} setProceedToNextStep={setProceedToNextStep}/>}
    </div>
  );
}