import { fetchApi, mapDateString } from './index';
import { WorkSchedule } from '../types';

export const getSchedulesForCoworkers = (scheduleId: string): Promise<WorkSchedule[]> => {
  return fetchApi(`/workschedules/${scheduleId}/coworkers/workschedules`, {})
    .then(async response => {
      return (await response.json()) as WorkSchedule[]
    })
    .then(workSchedules => workSchedules.map(schedule => ({
      ...schedule,
      TimeclockEntries: schedule.TimeclockEntries.map(entry => ({
        ...entry,
        TimeIn: mapDateString(entry.TimeIn),
        TimeOut: mapDateString(entry.TimeOut),
      }))
    })))
}


