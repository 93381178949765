import { getWebsiteUserFromLocalStorage } from "../utils/localStorage";

export function fetchApi(uri: string, args: RequestInit, enforceAuth: boolean = true) {
  return fetch(`${process.env.REACT_APP_DOMAIN}${uri}`, {
    method: 'GET',
    ...args,
    headers: {
      ...getHeaders(),
      ...args.headers
    }
  }).then((request) => {
    const url = window.location.href;

    if (enforceAuth && request.status === 401) {

      window.localStorage.clear();
      window.location.href = '/login';
    }

    return request;
  });
}

// Date strings are formatted: /Date(epochTimestamp)/
export function mapDateString(dateString: string | null): string | null {
  if (dateString === null) {
    return null;
  }
  return dateString.replace(/[^0-9]/g, '');
}

export * from './featureFlags';
export * from './schedules';
export * from './timeclock';

function getDomain() {
  const domain = window.location.host.split('.').shift();
  return (domain?.includes('localhost') || domain?.match(/\d{2}/g)) ? 'localhost' : domain;
}

export function getHeaders(): HeadersInit {
  const websiteUserData = getWebsiteUserFromLocalStorage();

  return {
    'Easy-Vote-Authenticated-User': `UserId:${websiteUserData.UserId}|CustomerId:${websiteUserData.CustomerId}|ZumoToken:${window.localStorage.getItem('ZumoToken')}`,
    'X-ZUMO-AUTH': `${window.localStorage.getItem('ZumoToken')}`,
    'ZUMO-API-VERSION': '2.0.0',
    'Content-Type': 'application/json',
  };
}

export function getHeadersFormData(): HeadersInit {
  const websiteUserData = getWebsiteUserFromLocalStorage();

  return {
    'Easy-Vote-Authenticated-User': `UserId:${websiteUserData.UserId}|CustomerId:${websiteUserData.CustomerId}|ZumoToken:${window.localStorage.getItem('ZumoToken')}`,
    'X-ZUMO-AUTH': `${window.localStorage.getItem('ZumoToken')}`,
    'ZUMO-API-VERSION': '2.0.0',
  }
}

export function getHeadersDownload(): HeadersInit {
  return {
    ...getHeaders(),
    'Content-Type': 'application/octet-stream',
  };
}

export async function getPoliticalParties() {
  const { CustomerId: customerId } = getWebsiteUserFromLocalStorage();

  return await fetchApi(`/customer/politicalparties/${customerId}`, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  }, false).then((resp) => resp.json());
}

export async function updateUserProfile(evUserId: string, userProfile: PollworkerUserProfileFromAPI) {
  return fetchApi(`/user/profile/${evUserId}/save`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(userProfile),
  }).then(resp => resp.json());
}

export async function getUserProfile(evUserId: string) {
  return fetchApi(`/user/profile/${evUserId}`, {
    method: 'GET',
    headers: getHeaders(),
  }).then(resp => resp.json());
}

export async function startPasswordReset(userId: string) {
  return await fetchApi(`/user/resetpassword`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({UserId: userId}),
  }).then((resp) => resp.json());
}

export async function performPasswordReset(resetCode: string, newPassword: string, userId: string) {
  return await fetchApi(`/user/setnewpassword`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      NewPassword: newPassword,
      ResetCode: resetCode,
      UserId: userId,
    }),
  }).then((resp) => resp.json());
}

export async function getWebsiteUser() {
  return await fetchApi(`/authentication/getwebsiteuser/${getDomain()}`, {
    headers: {
      'Content-Type': 'application/json',
    } 
  }).then((resp) => resp.json());
}

export async function getCurrentCustomer() {
  let resp = await fetchApi(`/user/current`, {
    headers: getHeaders(),
  });
  return await resp.json();
}

export async function getCurrentUser(evUserId: string) {
  const websiteUserData = getWebsiteUserFromLocalStorage();

  let resp = await fetchApi(`/user/current`, {
    headers: {
      'Easy-Vote-Authenticated-User': `UserId:${evUserId}|CustomerId:${websiteUserData.CustomerId}`,
    }
  });
  return await resp.json();
}

export function login(username: string, password: string): Promise<any> {
  return fetchApi(`/authentication/loginregistereduser?timezoneoffset=-5`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Basic ${btoa(`${username}:${password}`)}`,
    }
  })
  .then(async (resp) => [resp.ok, await resp.json()]);
}

export function validateFormData(data: PollworkerProfilePayload) {
  const websiteUser = getWebsiteUserFromLocalStorage();

  return fetchApi(`/user/${websiteUser.CustomerId}/validateapplicationdata`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(data),
  }).then((resp) => resp.json());
}

export function submitModernFormApplication(data: PollworkerProfilePayload) {
  const websiteUser = getWebsiteUserFromLocalStorage();

  return fetchApi(`/user/${websiteUser.CustomerId}/apply/dynamic`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(data),
  }).then((resp) => resp.json());
}

export function submitApplication(data: PollworkerProfilePayload) {
  const websiteUser = getWebsiteUserFromLocalStorage();

  return fetchApi(`/user/${websiteUser.CustomerId}/apply`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(data),
  }).then((resp) => resp.json());
}

export async function getPollworkerApplication(formId: string | null) {
  const {CustomerId} = getWebsiteUserFromLocalStorage();
  let path = `/user/${CustomerId}/applicationdata`;
  if (formId) path += `?formId=${formId}`;

  return fetchApi(path, {
    method: 'GET',
    headers: getHeaders()
  })
  .then(resp => resp.json());
}

export async function uploadDocument(evUserId: string, customerId: string, filename: string, file: File) {
  let success = false;

  const uploadFormData: FormData = new FormData();
  uploadFormData.append('uploadFile', file, file.name);

  const tempFileName = await fetch(`${process.env.REACT_APP_DOMAIN}/documents/uploaddocument`, {
    method: 'POST',
    headers: getHeadersFormData(),
    body: uploadFormData,
  }).then(resp => resp.json());

  if (tempFileName) {
    success = await fetchApi('/documents/add', {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify({
        DocumentName: filename,
        TempFileName: tempFileName,
        EVUserId: evUserId,
        CustomerId: customerId,
      }),
    }).then(resp => resp.json());
  }

  return [success, tempFileName];
}

export async function getElections() {
  // gets the list of elections for the current customer from the server
  let resp = await fetchApi('/customer/elections/getall', {
    headers: getHeaders(),
  });
  return await resp.json();
}

export async function getRequiredClasses(electionId: string) {
  // gets the required classes for the current election from the server
  // TODO: make this work
  let resp = await fetchApi(`/requiredclasses/customer/${electionId}/user`, {
    headers: getHeaders(),
  });
  return await resp.json();
}

export async function getRequiredClassSessions(electionId: string, classSessionId: string) {
  // gets the available class sessions for the current election from the server
  return fetchApi(`/requiredclasses/sessions/${electionId}/${classSessionId}`, {
    headers: getHeaders(),
  }).then(async (resp) => await resp.json());
}

export async function getScheduledClasses(electionId: string) {
  // gets the scheduled classes for the current election from the server
  return fetchApi(`/scheduledclasses/customer/${electionId}/user`, {
    headers: getHeaders(),
  }).then(async (resp) => await resp.json());
}

export async function getScheduledClassDetail(scheduledClassId: string) {
  // gets the scheduled class details for the current session from the server
  return fetchApi(`/scheduledclasses/sessiondetail/${scheduledClassId}`, {
    headers: getHeaders(),
  }).then(async (resp) => await resp.json());
}

export async function signUpForClassSession(classSignup: ClassSessionPayload, currentUser: any) {
  return fetchApi(`/requiredclasses/signup`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(classSignup),
  }).then(async (resp) => await resp.json());
}

export async function getClassDocumentBySessionId(sessionId: string, evUserId: string) {
  // gets the class document for the current session from the server
  return fetchApi(`/scheduledclasses/${sessionId}/${evUserId}/sessionscheduledocumentbyuserid`, {
    headers: getHeadersDownload(),
  }).then(async (resp) => await resp.blob());
}

export async function dropClass(classSignup: any, currentUser: any) {
  const websiteUserData = getWebsiteUserFromLocalStorage();
  // edits the work schedule for the scheduled date
  return fetchApi(`/scheduledclasses/dropclass`, {
    method: 'POST',
    headers: {
      'Easy-Vote-Authenticated-User': `UserId:${currentUser.EVUserId}|CustomerId:${websiteUserData.CustomerId}|ZumoToken:${window.localStorage.getItem('ZumoToken')}`,
      'X-ZUMO-AUTH': `${window.localStorage.getItem('ZumoToken')}`,
      'ZUMO-API-VERSION': '2.0.0',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(classSignup),
  }).then(async (resp) => await resp.json());

}

export async function getNews(customerId: string) {
  return fetchApi(`/blogs/${customerId}`, {
    headers: getHeaders(),
  }).then(async (resp) => await resp.json());
}

export async function getAvailabilitySurveyWorkDates(scheduleSurveyId: string, evUserId: string) {
  return fetchApi(`/survey/user/${scheduleSurveyId}/${evUserId}`, {
    headers: getHeaders(),
  }).then((resp) => resp.json());
}

export async function saveAvailabilitySurveyWorkDates(scheduleSurveyId: string, evUserId: string, dateSurveyed: string, isAvailable: boolean) {
  return fetchApi(`/survey/user/${scheduleSurveyId}/${evUserId}/saveresult`, {
    headers: getHeaders(),
    method: 'POST',
    body: JSON.stringify({ dateSurveyed, isAvailable }),
  }).then((resp) => resp.json());
}

export async function getDocumentFile(documentId: string) {
  return fetchApi(`/documents/getfromsecureblobstorage/${documentId}`, {
    headers: getHeadersDownload(),
  });
}

export async function getWorkSchedule(electionId: string) {
  // gets the work schedule for the current election from the server
  return fetchApi(`/workschedules/${electionId}/user`, {
    headers: getHeaders(),
  }).then(async (resp) => await resp.json());
}

export async function getScheduledCoworkers(scheduleId: string) {
  return fetchApi(`/workschedules/scheduledcoworkers/${scheduleId}`, {
    headers: getHeaders(),
  }).then(resp => resp.json());
}

export async function getWorkScheduleForEditing(scheduleId: string) {
  // gets the work schedule for the scheduled date from the server
  return fetchApi(`/workschedules/editworkschedule/${scheduleId}`, {
    headers: getHeaders(),
  }).then(async (resp) => await resp.json());
}

export async function editWorkSchedule(workSchedule: any) {
  // edits the work schedule for the scheduled date
  return fetchApi(`/workschedules/edit`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(workSchedule),
  }).then(async (resp) => await resp.json());
}

export async function getListOfDocuments(evUserId: string) {
  // gets the list of documents for the current user from the server
  return fetchApi(`/documents/${evUserId}`, {
    headers: getHeaders(),
  }).then(async (resp) => await resp.json());
}

export async function getEmployeeImage(customerId: string, userId: string) {
  // TODO: make this work
  // gets the employee image for the current user from the server
  return await fetchApi(`/images/${customerId}/${userId}/employeeimage`, {
    headers: getHeaders(),
  })
  .then(async (resp) => {
    if (!resp.ok) return Promise.reject();
    return await resp.blob();
  });
}

export async function verifyCreateCredentials(evUserId: string, verificationInfo: any) {
  return fetchApi(`/user/${evUserId}/verifycreatecredentials`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(verificationInfo),
  }).then(async (resp) => await resp.json());
}

export async function verifyUserIdUnique(userId: string) {
  return fetchApi(`/user/verifyuseridunique`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({UserName: userId}),
  }).then(async (resp) => await resp.json());
}

export async function updateLoginCredentials(evUserId: string, loginCredentials: any) {
  return fetchApi(`/user/${evUserId}/updatelogincredentials`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(loginCredentials),
  }).then(async (resp) => await resp.json());
}
