import React, { useMemo, useState } from "react";
import {Bars3Icon} from "@heroicons/react/24/solid";
import { useAppSelector } from "../hooks";
import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Link, useLocation } from "wouter";
import {useSessionStorage} from "react-use";
import HeaderAuthed from "./HeaderAuthed";
import DomainLogo from "./DomainLogo";
import { SESSION_STORAGE_KEYS } from "../constants";
import useIsLoggedIn from "./selectors/useIsLoggedIn";
import {HiddenLink, ScreenReaderHidden, ScreenReaderOnly} from "shared/src/components/Accessibility";
import { Separator, Button } from "shared/src/components/ui";

export default function Header() {
  const currentUser = useAppSelector((state) => state.currentPollworkerUser);
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
  const [cookieAlert, setCookieAlert] = useSessionStorage(SESSION_STORAGE_KEYS.COOKIE_ALERT, true);
  const isLoggedIn = useIsLoggedIn();
  const [location, navigate] = useLocation();

  const navigation = useMemo(() => {
    return [
      { label: 'Home', value: 'home', onClick: () => navigate('/') },
      { label: 'Apply', value: 'apply', onClick: () => navigate('/apply') },
    ];
  }, [currentUser]);

  return (
    <section>
      {
        isLoggedIn ? (
          <HeaderAuthed />
        ) : (
          <header role="banner">
            <HiddenLink href='#main-nav'>Skip to main navigation</HiddenLink>
            {
              location !== '/apply' ? (

                <div className="relative isolate flex justify-center items-center gap-x-6 overflow-hidden bg-gray-50 px-6 py-2.5 sm:px-3.5">
                  <div
                    className="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
                    aria-hidden="true"
                  >
                    <div
                      className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-ev-red to-ev-blue opacity-30"
                      style={{
                        clipPath:
                          'polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)',
                      }}
                    />
                  </div>
                  <div
                    className="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
                    aria-hidden="true"
                  >
                    <div
                      className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-ev-red to-ev-blue opacity-30"
                      style={{
                        clipPath:
                          'polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)',
                      }}
                    />
                  </div>
                  <div className="flex sm:flex-wrap items-center gap-x-4 gap-y-2">
                    <p className="text-sm leading-6 text-gray-900">
                      <strong className="sm:inline hidden font-semibold">Pollworker Application</strong>
                      <svg viewBox="0 0 2 2" className="mx-2 sm:inline hidden h-0.5 w-0.5 fill-current" aria-hidden="true">
                        <circle cx={1} cy={1} r={1} />
                      </svg>
                      <span className='sm:inline hidden'>Apply today to become a poll worker!</span>
                      <span className='sm:hidden inline'>Become a poll worker!</span>
                    </p>
                    <Link
                      to="/apply"
                      className="flex-none rounded-full bg-gray-900 px-3.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                    >
                      Apply Now! <ScreenReaderHidden>&rarr;</ScreenReaderHidden>
                    </Link>
                  </div>
                </div>
              ) : null
            }
            <nav id='main-nav' className="mx-auto flex items-center justify-between p-6 lg:px-8" aria-label="Global" role="navigation">
              <DomainLogo />
              <div className="flex lg:hidden">
                <Button
                  type="button"
                  className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
                  onClick={() => setMobileMenuOpen(true)}
                >
                  <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                  <ScreenReaderOnly>Open main menu</ScreenReaderOnly>
                </Button>
              </div>
              <div className="hidden lg:flex lg:gap-x-6">
                {
                  navigation.map((item) => {
                    return (
                      <Button variant='tertiary' key={item.label} onClick={item.onClick} className="text-sm font-semibold leading-6 text-gray-900">
                        {item.label}
                      </Button>
                    );
                  })
                }
                <Separator />
                <Button asChild variant='tertiary'>
                  <Link
                    to='/login'
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    Log in <ScreenReaderHidden>&nbsp; &rarr;</ScreenReaderHidden>
                  </Link>
                </Button>
              </div>
            </nav>
            <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
              <div className="fixed inset-0 z-10" />
              <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                <div className="flex items-center justify-between">
                  <button
                    type="button"
                    className="-m-2.5 rounded-md p-2.5 text-gray-700"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-6 flow-root">
                  <div className="-my-6 divide-y divide-gray-500/10">
                    <div className="space-y-2 py-6">
                      {
                        navigation.map((item) => {
                          return (
                            <button
                              key={item.label}
                              onClick={() => {
                                setMobileMenuOpen(false);
                                item.onClick();
                              }}
                              className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 w-full text-left"
                            >
                              {item.label}
                            </button>
                          )
                        })
                      }
                    </div>
                    <div className="py-6">
                      <Link
                        to='/login'
                        onClick={() => setMobileMenuOpen(false)}
                        className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                      >
                        Log in
                      </Link>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Dialog>
          </header>
        )
      }
      {
        cookieAlert ? (
          <div className="pointer-events-none fixed inset-x-0 z-50 w-full sm:w-auto bottom-0 px-6 pb-6">
            <div className="pointer-events-auto mr-auto max-w-xl rounded-xl bg-white p-6 shadow-lg ring-1 ring-gray-900/10">
              <p className="text-sm leading-6 text-gray-900">
                We use cookies and other similar technologies to improve your browsing experience and
                the functionality of our site. By using this site, you consent to the storing on your
                device of the cookie information. Your current cookie settings can be changed at any
                time by clicking your cookie preferences or settings in your browser. If cookies are
                not enabled, this site may not function correctly. For the best experience, please use
                one of the following supported browsers: Chrome, FireFox, or Microsoft Edge.
              </p>
              <div className="mt-4 flex items-center gap-x-5">
                <button
                  type="button"
                  className="rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                  onClick={() => setCookieAlert(false)}
                >
                  Accept all
                </button>
              </div>
            </div>
          </div>
        ) : null
      }
    </section>
  )
}
