import React, {useEffect} from 'react'
import { ResetPassword } from 'shared/src/components';
import {useLocation} from "wouter";
import { startPasswordReset, performPasswordReset } from '../fetchers'
import { SESSION_STORAGE_KEYS } from "../constants";

export default function ResetPasswordScreen() {
  const [, navigate] = useLocation();

  const userId = window.sessionStorage.getItem(SESSION_STORAGE_KEYS.FORGOT_PASSWORD_USER_ID);

  useEffect(() => {
    if (!userId) navigate('~/login/forgot');
  }, []);

  const handleSuccessfulReset = (username: string) => {
    setTimeout(() => {
      navigate('~/login');
    }, 5000)
  }

  return (
    <ResetPassword startPasswordReset={startPasswordReset} performPasswordReset={performPasswordReset} onSuccessfulReset={handleSuccessfulReset}  />
  )
}
