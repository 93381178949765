import { useEffect } from 'react';

import { checkFeatureFlag } from 'pollworker/src/fetchers';
import { selectFeatureFlags, setFeatureFlagCheck } from 'pollworker/src/state/featureFlag';
import { AppState } from 'pollworker/src/state/store';

import { useAppDispatch, useAppSelector } from './index';

export const useIsFeatureFlagEnabled = (flag: string): boolean => {
  const dispatch = useAppDispatch();
  const flags = useAppSelector(selectFeatureFlags);
  const currentUser = useAppSelector((state: AppState) => state.currentPollworkerUser);

  useEffect(() => {
    if (flags[flag] === undefined) {
      checkFeatureFlag(flag).then((enabled: boolean) => {
        dispatch(setFeatureFlagCheck({ name: flag, enabled }));
      }, () => {
        dispatch(setFeatureFlagCheck({ name: flag, enabled: false }));
      })
    }
  }, [currentUser]);

  return flags[flag];
}
