import React from "react";
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";
import pollworkerStore from "./state/store";
import App from "./App";
import { ErrorBoundary } from "shared/src/components";
import Container from "./components/Container";
import './index.css';
import * as Sentry from 'shared/src/setupSentry';
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';

Sentry.initialSetup();

dayjs.extend(LocalizedFormat);
dayjs.extend(utc);
dayjs.extend(customParseFormat);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={pollworkerStore}>
    <ErrorBoundary>
      <Container>
        <App/>
      </Container>
    </ErrorBoundary>
  </Provider>
);
